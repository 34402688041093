import clsx from 'clsx';
import * as React from 'react';
import { ChildWidgetProps } from '..';
import styles from './consentAgreement.module.scss';
import { BorrowerApi } from '../../../../apis/borrowerApi';
import { GoogleAnalyticsCategory, LoanDocumentType } from '../../../../models/dtos/enums';
import { DocumentsApi } from '../../../../apis/documentsApi';
import { event } from '../../../../utils/tracking';
import { BorrowerStore } from '../../../../stores/borrowerStore';
import { Info } from 'phosphor-react';

export interface ConsentAgreementProps extends ChildWidgetProps {
  onAgree: (agreed: boolean) => void;
  onDisagree(): void;
  borrowerApi: BorrowerApi;
  documentsApi: DocumentsApi;
  borrowerStore: BorrowerStore;
}

export interface ConsentAgreementState {
  checked: boolean;
  disabledDisagree: boolean;
}

class ConsentAgreement extends React.Component<
  ConsentAgreementProps,
  ConsentAgreementState
> {
  constructor(props: ConsentAgreementProps) {
    super(props);

    this.props.widgetStore.setMenuVisibility(false);
  }

  state = {
    checked: false,
    disabledDisagree: false,
  };

  setBorrowerStoreValue = async () => {
    if (this.props.borrowerStore.borrower) return;
    try {
      const borrower = await this.props.borrowerApi.getBorrower();
      if (borrower) this.props.borrowerStore.setBorrower(borrower);
    } catch (error) {
      this.props.borrowerStore.setBorrower(undefined);
    }
  };

  handleCheckboxChange = () => {
    this.setState({ ...this.state, checked: !this.state.checked });
  };

  handleAgree = async () => {
    event({
      category: GoogleAnalyticsCategory.ConsentAgreementWidget,
      action: 'Click Agree',
    });
    try {
      this.props.onAgree(true);

      this.setState({ ...this.state, disabledDisagree: true });
      await this.props.borrowerApi.updateDpaConsent(true);
      await this.setBorrowerStoreValue();
      this.props.onAgree(false);
    } catch {
      this.props.onError();
    }
  };

  handleDisagree = () => {
    event({
      category: GoogleAnalyticsCategory.ConsentAgreementWidget,
      action: 'Click Go Back',
    });
    this.props.onDisagree();
  };

  handleOnClickConsent = async () => {
    const document = await this.props.documentsApi.getLoanDocument(
      LoanDocumentType.InstaCashDataPrivacyAndConsent
    );
    if (document) window.open(document.url);
  };

  render() {
    return (
      <div className={styles.widget}>
        <div className={clsx(styles.content, styles.grayScrollbar)}>
          <p className={styles.title}>
            <Info className={styles.icon} size={24} weight="fill" />
            Welcome to ReadyCash!
          </p>
          <p className={styles.bodyText}>
            In order to get you the best possible terms, some of your information will be
            shared with our lending partner. If you'd like to know more about this, you
            can read the
            <span
              className={clsx('font-weight-bold pl-1', styles.consent)}
              onClick={this.handleOnClickConsent}
            >
              Privacy Policy
            </span>
            .
          </p>
          <p className={styles.bodyText}>
            If you have any concerns, feel free to contact
            <span className={clsx('font-weight-bold pl-1', styles.subtext)}>
              experts@sprout.ph.
            </span>
          </p>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="tnc-cb"
              onChange={this.handleCheckboxChange}
            />
            <label className="custom-control-label" htmlFor="tnc-cb">
              I have read and understood the privacy policy
            </label>
          </div>
        </div>
        <div className={styles.footer}>
          <button
            className={`${styles.button} primaryBtn`}
            onClick={this.handleAgree}
            disabled={!this.state.checked}
          >
            <span className={styles.buttonText}>Agree</span>
          </button>
          <button
            className={`${styles.buttonFlat} greenFlatBtn`}
            onClick={this.handleDisagree}
            disabled={this.state.disabledDisagree}
          >
            <span>Go Back</span>
          </button>
        </div>
      </div>
    );
  }
}

export default ConsentAgreement;

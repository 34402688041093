import * as React from 'react';
import styles from './loanRequested.module.scss';
import { ChildWidgetProps } from '..';
import { event } from '../../../../utils/tracking';
import { GoogleAnalyticsCategory } from '../../../../models/dtos/enums';
import { Info } from 'phosphor-react';
import FooterWidget from '../../../_common/footerWidget';

export interface LoanRequestedWidgetProps extends ChildWidgetProps {
  onViewLoanHistory(): void;
}

export interface LoanRequestedWidgetState {}

class LoanRequestedWidget extends React.Component<
  LoanRequestedWidgetProps,
  LoanRequestedWidgetState
> {
  constructor(props: LoanRequestedWidgetProps) {
    super(props);

    this.props.widgetStore.setMenuVisibility(true);
  }

  componentDidMount() {
    this.props.onFinished();
  }

  handleOnViewLoanHistory = () => {
    event({
      category: GoogleAnalyticsCategory.LoanRequestedWidget,
      action: 'Click View my History',
    });
    this.props.onViewLoanHistory();
  };

  render() {
    return (
      <div>
        <div className={styles.widget}>
          <p className={styles.header}>
            <Info color={styles.colorInfo} weight='fill' size={24}/>Your
            money is on its way!
          </p>
          <div className={styles.content}>
          <p>
            You will see the status of your cash advance in this widget soon. Sit tight!
            </p>
            <p>If you have any concerns, feel free to contact <br/><b>experts@sprout.ph.</b></p>
          </div>
          <FooterWidget>
          <button className={`${styles.button} outlinedBtn`}  onClick={this.handleOnViewLoanHistory}>
          <span>View My History</span>
        </button>
          </FooterWidget>
        </div>
      </div>
    );

  }
}

export default LoanRequestedWidget;

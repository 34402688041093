import React, { FC } from 'react';
import style from './numericDisplay.module.scss';

export interface NumericDisplayProps {
  showComma: boolean;
  decimalCount?: number;
  value: number;
  category?: string;
  className?: string;
  maxDisabled?: boolean;
  minDisabled?: boolean;
}

const NumericDisplay: FC<NumericDisplayProps> = ({
  showComma,
  value,
  decimalCount = 0,
  category,
  className,
  maxDisabled,
  minDisabled,
}) => {
  const numericOptions = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };

  const otherOptions = {
    minimumFractionDigits: decimalCount,
  };

  return (
    <span
      className={`${
        category === 'bttn'
          ? maxDisabled || minDisabled === true
            ? style.bttnStyleDisabled
            : style.bttnStyle
          : style.bttnStyle2
      } ${className}`}
    >
      {showComma
        ? category !== 'numeric'
          ? value.toLocaleString(navigator.language, otherOptions)
          : value.toLocaleString(navigator.language, numericOptions)
        : value}
    </span>
  );
};

export default NumericDisplay;

import {
  ILoanQuoteDto,
  ILoanDto,
  ICreateLoanDto,
  CreateLoanDtoNew,
  ReasonDto,
  LoanRangesDto,
  LoanQuoteRequest,
  OutstandingLoanBalanceDto,
  TotalOutstandingBalanceBreakdownPaymentsDto,
} from '../models/dtos/loan';
import { AxiosApiBase } from './axiosApiBase';
import { ApiBase } from './apiBase';
import { Observable } from 'rxjs';
import { IPagedResultDto, PaginationParam } from '../models/dtos/pagedResult';
import { LoanStatusCategory } from '../models/dtos/enums';

export class LoansApi {
  private ajax = new ApiBase();
  private axios = new AxiosApiBase();

  private baseUrl = '/api/v1/loans';

  // This should be ajax call so that it can be included in the switchMap pipe.
  getLoanQuoteObservable(quote: LoanQuoteRequest): Observable<ILoanQuoteDto> {
    return this.ajax.post(`${this.baseUrl}/quote`, quote);
  }

  getOutstandingLoan(): Promise<ILoanDto> {
    return this.axios.get<ILoanDto>(`${this.baseUrl}/outstandingLoan`);
  }

  requestLoan(data: ICreateLoanDto): Promise<ILoanDto> {
    return this.axios.post<ILoanDto>(this.baseUrl, data);
  }

  requestLoanNew(data: CreateLoanDtoNew): Promise<ILoanDto> {
    return this.axios.post<ILoanDto>(this.baseUrl, data);
  }

  getReasons(): Promise<ReasonDto[]> {
    return this.axios.get<ReasonDto[]>(`${this.baseUrl}/reasons`);
  }

  getLoanHistory(paginationParam?:PaginationParam): Promise<IPagedResultDto<ILoanDto[]>> { 
    let queryString = this.generateQueryString(paginationParam);
    queryString = (queryString != '')? '?'+queryString:''; 
     
    return this.axios.get<IPagedResultDto<ILoanDto[]>>(`${this.baseUrl}/history`+queryString);
  }

  getLoanRanges(): Promise<LoanRangesDto> {
    return this.axios.get<LoanRangesDto>(`${this.baseUrl}/ranges`);
  }

  getAvailableTerms(loanAmount: number, maxLoanAmount: number): Promise<number[]> {
    return this.axios.get<number[]>(
      `${this.baseUrl}/terms?loanAmount=${loanAmount}&maximumLoanAmount=${maxLoanAmount}`
    );
  }
 
  async getOutstandingLoanBalance(category?:LoanStatusCategory)   {
    const param = (category)? `?category=${category}`:'';
    return await this.axios.get<OutstandingLoanBalanceDto>(`${this.baseUrl}/totalOutstandingBalance${param}`);
  }

  getTotalOutstandingBalanceBreakDown(): Promise<TotalOutstandingBalanceBreakdownPaymentsDto> {
    return this.axios.get<TotalOutstandingBalanceBreakdownPaymentsDto>(`${this.baseUrl}/totalOutstandingBalance/breakdown`);
  }
  private generateQueryString(paginationParam?:PaginationParam){
    return this.ajax.objectToQueryString((paginationParam == null || paginationParam == undefined) ?
              this.ajax.getDefaultPaginationParam() : paginationParam);
  }
}

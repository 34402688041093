import { action, observable } from 'mobx';

export class WidgetStore {
  @observable
  menuShowing = false;

  @action
  setMenuVisibility(value: boolean) {
    this.menuShowing = value;
  }

  @observable
  customHeader = '';

  @action
  setCustomHeader(value: string) {
    this.customHeader = value;
  }

  @observable
  isCreditLine: boolean; 
  
  @action
  setCreditLineFlag(creditLineFlag: boolean) {
    this.isCreditLine = creditLineFlag;
  }

  @action
  resetCreditLineFlag() {
    this.setCreditLineFlag(false); 
  }
}

import * as React from 'react';
import styles from './borrowerNotEligibleWidget.module.scss';
import { ChildWidgetProps } from '../..';
import { event } from '../../../../../utils/tracking';
import { GoogleAnalyticsCategory } from '../../../../../models/dtos/enums';
import { XSquare, Info } from 'phosphor-react';

export interface BorrowerNotEligibleWidgetProps extends ChildWidgetProps {
  reasons: string[];
  url: string;
}

class BorrowerNotEligibleWidget extends React.Component<BorrowerNotEligibleWidgetProps> {
  constructor(props: BorrowerNotEligibleWidgetProps) {
    super(props);

    this.props.widgetStore.setMenuVisibility(false);
  }
  componentDidMount() {
    this.props.onFinished();

    for (let i = 0; i < this.props.reasons?.length; i++) {
      event({
        category: GoogleAnalyticsCategory.BorrowerNotEligibleWidget,
        action: 'Non-Eligibility Reasons',
        label: this.props.reasons[i],
      });
    }
  }

  render() {
    return (
      <div className={styles.widget}>
        <p className={styles.title}>
          <Info color={styles.colorInfo} size={24} weight="fill" /> We want to help you
          out...
        </p>
        <p className={styles.desc}>
          Kindly coordinate with HR to resolve the issues below with your Sprout account.
        </p>
        <p className={styles.desc}>
          If you have any concerns, feel free to contact <b>experts@sprout.ph</b>.
        </p>
        <div className={styles.details}>
          {this.props.reasons?.map((r, i) => {
            return (
              <div id="reasons-container" key={i} className={styles.reason}>
                <div id="error-icon" className={styles.errorIcon}>
                  <XSquare color={styles.colorError} size={24} weight="fill" />
                </div>
                <div id="reasons-description" className={styles.reasonContent}>
                  {r}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default BorrowerNotEligibleWidget;

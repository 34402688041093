import { GoogleAnalyticsCategory, WidgetStateEnum } from './enums';
import { ILoanDto } from './loan';

export interface WidgetDto {
  state: WidgetStateEnum;
}

export interface NonEligibleWidgetDto extends WidgetDto {
  nonEligibilityReasons: string[];
  url: string;
}

export interface OutstandingLoanWidgetDto extends WidgetDto {
  outstandingLoans: ILoanDto[];
}

export interface BenefitsWidgetDto extends WidgetDto {
  url: string;
  text: string;
  buttonText: string;
  base64Header: string;
  base64Content: string;
}

export const convertNumberToWidgetStateEnum = (value: number) => {
  switch (value) {
    case 0:
      return WidgetStateEnum.BorrowerNotEligible;
    case 1:
      return WidgetStateEnum.CompanyNotEligible;
    case 2:
      return WidgetStateEnum.Eligible;
    case 3:
      return WidgetStateEnum.OutstandingLoan;
    case 4:
      return WidgetStateEnum.EmploymentStatusChanged;
    case 5:
      return WidgetStateEnum.SystemUnderMaintenance;
    case 6:
      return WidgetStateEnum.LoanRequested;
    case 7:
      return WidgetStateEnum.Unconsented;
  }
};

export const convertWidgetStateToGACategory = (state: WidgetStateEnum) : GoogleAnalyticsCategory => {
  switch (state) {
    case WidgetStateEnum.BorrowerNotEligible:
      return GoogleAnalyticsCategory.BorrowerNotEligibleWidget;
    case WidgetStateEnum.Eligible:
      return GoogleAnalyticsCategory.EligibleWidget;
    case WidgetStateEnum.OutstandingLoan:
      return GoogleAnalyticsCategory.OutstandingLoanWidget;
    case WidgetStateEnum.SystemUnderMaintenance:
      return GoogleAnalyticsCategory.SystemUnderMaintenanceWidget;
    case WidgetStateEnum.Error:
      return GoogleAnalyticsCategory.ErrorWidget;
    case WidgetStateEnum.LoanRequested:
      return GoogleAnalyticsCategory.LoanRequestedWidget;
    case WidgetStateEnum.Unconsented:
      return GoogleAnalyticsCategory.UnconsentedWidget;
    case WidgetStateEnum.ConsentAgreement:
      return GoogleAnalyticsCategory.ConsentAgreementWidget;
    case WidgetStateEnum.BenefitsAdmin:
      return GoogleAnalyticsCategory.BenefitsAdminWidget;
    case WidgetStateEnum.BenefitsEmployee:
      return GoogleAnalyticsCategory.BenefitsEmployeeWidget;
    case WidgetStateEnum.Unauthorized:
      return GoogleAnalyticsCategory.UnauthorizedWidget;
    default:
      break;
  }
}

import * as React from 'react';
import styles from './simpleLineProgress.module.css';

const SimpleLineProgress = () => {
  return (
    <div className={styles.progress}>
      <div className={styles.line} />
    </div>
  );
};

export default SimpleLineProgress;

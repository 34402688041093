import * as React from 'react';
import CircleProgress from '../circle/circleProgress';
import styles from './page.module.scss';

export interface PageProgressProps {}

const PageProgress: React.FC<PageProgressProps> = () => {
  return (
    <div className={styles.page}>
      <CircleProgress width={120} height={120} />
    </div>
  );
};

export default PageProgress;

import { AxiosApiBase } from './axiosApiBase';

export class AdminApi extends AxiosApiBase {
  private baseUrl = '/api/v1/admin';

  getClientDomain(): Promise<string> {
    return this.get<string>(`${this.baseUrl}/client/domain`);
  }

  getSupportEmail(): Promise<string> {
    return this.get<string>(`${this.baseUrl}/supportEmail`);
  }
}

import * as React from 'react';
import styles from './systemMaintenanceWidget.module.scss';
import { ArrowsClockwise, Wrench } from 'phosphor-react';
import { ChildWidgetProps } from '..';
import { event } from '../../../../utils/tracking';

import { GoogleAnalyticsCategory } from '../../../../models/dtos/enums';

export interface SystemMaintenanceWidgetProps extends ChildWidgetProps {
  onRetry(): void;
}

export interface SystemMaintenanceWidgetState {}

class SystemMaintenanceWidget extends React.Component<
  SystemMaintenanceWidgetProps,
  SystemMaintenanceWidgetState
> {
  constructor(props: SystemMaintenanceWidgetProps) {
    super(props);
    this.props.widgetStore.setMenuVisibility(true);
  }
  componentDidMount() {
    this.props.onFinished();
  }

  handleRetry = () => {
    event({
      category: GoogleAnalyticsCategory.SystemUnderMaintenanceWidget,
      action: 'Click Retry',
    });
    this.props.onRetry();
  };

  render() {
    return (
      <div className={styles.widget}>
        <p>
          <Wrench color="#FF7F00" weight="fill" size={24} />
          We’ll be back in an instant!
        </p>
        <p>
          Our system is currently being updated to serve you better. We’ll attend to your
          requests soon!
        </p>
        <div className={styles.footer}>
          <button className={`${styles.button} outlinedBtn`} onClick={this.handleRetry}>
            <span className={styles.retry}>
              <ArrowsClockwise size={15} weight="bold" />
            </span>
            <span>Refresh</span>
          </button>
        </div>
      </div>
    );
  }
}

export default SystemMaintenanceWidget;

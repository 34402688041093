import React from 'react';
import styles from './footerWidget.module.scss';
import clsx from 'clsx';

export interface FooterWidgetProps {
  className?: string;
}



const FooterWidget: React.FC<FooterWidgetProps> = ({ children, className}) => {


  return (
    <div className={clsx(styles.footer, className)} >
      {children}
    </div>
  );
};

export default FooterWidget;

import React, { FC } from 'react';
import styles from './amount.module.scss';
import clsx from 'clsx';
import NumericDisplay from '../numeric/numericDisplay';

export enum CurrencyPlacement {
  left,
  right,
}

export interface AmountProps {
  className?: string;
  currency?: string;
  currencyClassName?: string;
  currencyPlacement?: CurrencyPlacement;
  decimalCount?: number;
  showComma?: boolean;
  value: number;
  valueClassName?: string;
  label?: string;
}

const Amount: FC<AmountProps & React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  currency = 'PHP',
  currencyClassName,
  currencyPlacement = CurrencyPlacement.right,
  decimalCount = 2,
  showComma = true,
  value,
  valueClassName,
  label,
  ...otherProps
}) => {
  return (
    <div className={clsx(styles.container, className)} {...otherProps}>
      <div className={styles.wrapper}>
        {currencyPlacement === CurrencyPlacement.left && (
          <div className={clsx(styles.currency, styles.currencyLeft, currencyClassName)}>
            {currency}
          </div>
        )}
        <div className={valueClassName}>
          <NumericDisplay
            decimalCount={decimalCount}
            showComma={showComma}
            value={value}
            className={label ==="redValue" ? styles.valueSpan2 : label ==="breakSpan" ? styles.breakSpan :styles.valueSpan}
          />
        </div>
        {currencyPlacement === CurrencyPlacement.right && (
          <div className={`${styles.currencyRight} ${currencyClassName}`}>
            {currency}
          </div>
        )}
      </div>
    </div>
  );
};

export default Amount;

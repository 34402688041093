import * as React from 'react';
import styles from './unconsented.module.scss';
import { ChildWidgetProps } from '..';
import clsx from 'clsx';
import { CompanyApi } from '../../../../apis/companyApi';
import { event } from '../../../../utils/tracking';
import { GoogleAnalyticsCategory } from '../../../../models/dtos/enums';
import FooterWidget from '../../../_common/footerWidget';
import { Info } from 'phosphor-react';

export interface UnconsentedWidgetProps extends ChildWidgetProps {
  companyApi: CompanyApi;
  onClick(): void;
}

export interface UnconsentedWidgetState {
  spiel: string;
  spielNote: string;
}

class UnconsentedWidget extends React.Component<
  UnconsentedWidgetProps,
  UnconsentedWidgetState
> {
  constructor(props: UnconsentedWidgetProps) {
    super(props);

    this.props.widgetStore.setMenuVisibility(false);
  }
  state = {
    spiel: '',
    spielNote: '',
  };

  async componentDidMount() {
    try {
      const setting = await this.props.companyApi.getCompanySetting();
      this.setState({
        spiel: setting.marketingSpiel,
        spielNote: setting.marketingSpielNote,
      });
      if (!setting) this.props.onError();
      else this.props.onFinished();
    } catch (error) {
      this.props.onError();
    }
  }

  handleOnClick = () => {
    event({
      category: GoogleAnalyticsCategory.UnconsentedWidget,
      action: 'Click Get Cash',
    });
    this.props.onClick();
  };

  render() {
    return (
      <div className={styles.widget}>
        <div className={`${styles.content} ${styles.grayScrollbar}`}>
          <p className={styles.title}>
            Welcome to ReadyCash!
          </p>
          <p
            className={styles.subHead}
            dangerouslySetInnerHTML={{
              __html: this.state.spiel,
            }}
          ></p>
          <p
            className={styles.note}
            dangerouslySetInnerHTML={{
              __html: this.state.spielNote,
            }}
          ></p>
        </div>
        <FooterWidget>
          <button className={`${styles.button} primaryBtn`} onClick={this.props.onClick}>
            <span>Continue</span>
          </button>
        </FooterWidget>
      </div>
    );
  }
}

export default UnconsentedWidget;

import { observable, action } from 'mobx';

export class AuthStore {
  @observable
  token = '';

  @action
  setToken(value: string) {
    this.token = value;
  }
}

import { AxiosApiBase } from './axiosApiBase';
import { LoanDocumentType } from '../models/dtos/enums';
import { LoanDocumentDto } from '../models/dtos/loan';

export class DocumentsApi {
  private axios = new AxiosApiBase();
  private baseUrl = '/api/v1/documents';

  getLoanDetailsDocument(loanId: number): Promise<any> {
    return this.axios.download(`${this.baseUrl}/loan/${loanId}`);
  }

  getLoanDocument(type: LoanDocumentType) {
    return this.axios.get<LoanDocumentDto>(`${this.baseUrl}/${type}`);
  }
}

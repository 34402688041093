import * as React from 'react';
import SalaryDeductions, {
  SalaryDeduction,
} from '../../salaryDeductions/salaryDeductions';
import { LoansApi } from '../../../apis/loansApi';
import { AuthStore } from '../../../stores/authStore';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import { ILoanDto, OutstandingLoanBalanceDto } from '../../../models/dtos/loan';
import NumericDisplay from '../../_common/displays/numeric/numericDisplay';
import DateTimeDisplay, { DateTimeDisplayFormat } from '../../_common/displays/dateTime';
import styles from './outstandingLoanDetailsWidget.module.css';
import { BorrowerApi } from '../../../apis/borrowerApi';
import { IBorrowerDto } from '../../../models/dtos/borrower';
import ListItem from '../../_common/lists/listItem';
import CircleProgress from '../../_common/progress/circle/circleProgress';
import { LoanPaymentStatus } from '../../../models/dtos/enums';
import { dependencies } from '../../../models/constants';

export interface OutstandingLoanWidgetProps extends RouteComponentProps<{}> {
  loansApi: LoansApi;
  authStore: AuthStore;
  borrowerApi: BorrowerApi;
}

export interface OutstandingLoanWidgetState {
  outstandingLoan: ILoanDto;
  borrower: IBorrowerDto;
  outstandingLoanBalance: OutstandingLoanBalanceDto;
}

@inject(...dependencies)
@observer
class OutstandingLoanWidget extends React.Component<
  OutstandingLoanWidgetProps,
  OutstandingLoanWidgetState
> {
  state = {
    outstandingLoan: undefined as ILoanDto,
    borrower: undefined as IBorrowerDto,
    outstandingLoanBalance: undefined as OutstandingLoanBalanceDto,
  };

  async componentDidMount() {
    const [outstandingLoan, borrower,outstandingLoanBalance] = await Promise.all([
      this.props.loansApi.getOutstandingLoan(),
      this.props.borrowerApi.getBorrower(),
      this.props.loansApi.getOutstandingLoanBalance(),
    ]);
    this.setState({
      outstandingLoan,
      borrower,
      outstandingLoanBalance
    });
  }

  render() {
    if (!this.state.borrower || !this.state.outstandingLoan|| !this.state.outstandingLoanBalance) {
      return (
        <div className={styles.loading}>
          <CircleProgress />
        </div>
      );
    }
    const {
      applicationDate,
      loanAmount,
      convenienceFee,
      totalAmountDue,
      referenceId,
      lastDueDate,
      payments,
    } = this.state.outstandingLoan;
    const deductions: SalaryDeduction[] = payments.map(d => {
      return {
        payPeriod: d.period,
        amountDue: d.amountDue + (d.penalty || 0),
        paymentDue: d.dueDate,
        status: d.paymentStatus,
      };
    });
    const { bankName } = this.state.borrower.employmentDetail.payrollDetail;
    return (
      <div style={{ userSelect: 'none' }}>
        <SalaryDeductions deductions={deductions} styles={styles} showStatus={true} />
        <ListItem label="Reference Code" value={referenceId} />
        <ListItem
          label="Amount Borrowed"
          value={<NumericDisplay value={loanAmount} showComma={true} decimalCount={2} />}
        />
        <ListItem
          label="Convenience Fee"
          value={
            <NumericDisplay value={convenienceFee} showComma={true} decimalCount={2} />
          }
        />
        {payments
          .filter(
            p =>
              p.penalty &&
              p.penalty > 0 &&
              p.paymentStatus.toLocaleLowerCase() !==
                LoanPaymentStatus.Paid.toString().toLocaleLowerCase()
          )
          .map((p, i) => {
            return (
              <ListItem
                key={i}
                label={`Penalty for Pay Period ${p.period}`}
                value={
                  <NumericDisplay value={p.penalty} showComma={true} decimalCount={2} />
                }
              />
            );
          })}
        <ListItem
          label="Total Amount Due"
          value={
            <NumericDisplay value={totalAmountDue} showComma={true} decimalCount={2} />
          }
        />
        <ListItem
          label="Date Requested"
          value={
            <DateTimeDisplay
              value={applicationDate}
              format={DateTimeDisplayFormat.Month_d_yyyy}
            />
          }
        />
        <ListItem
          label="Due Date"
          value={
            <DateTimeDisplay
              value={lastDueDate}
              format={DateTimeDisplayFormat.Month_d_yyyy}
            />
          }
        />
        <ListItem
          label="Bank"
          value={`${bankName !== null ? bankName : ''}
           - 
          ${this.state.borrower.employmentDetail.payrollDetail.bankAccountNumber}`}
        />
      </div>
    );
  }
}

export default OutstandingLoanWidget;

import * as React from 'react';
import styles from './outstandingLoan.module.scss';
import { Warning, Info } from 'phosphor-react';
import { ChildWidgetProps } from '..';
import { AccordionContainer, SimpleAccordion } from '../../../_common/accordion';
import {
  ILoanDto,
  LoanRangesDto,
  OutstandingLoanBalanceDto,
} from '../../../../models/dtos/loan';
import OutstandingLoanContent from './outstandingLoanContent';
import { BorrowerApi } from '../../../../apis/borrowerApi';
import { IBorrowerDto } from '../../../../models/dtos/borrower';
import { event } from '../../../../utils/tracking';
import { FeatureFlagEnum, GoogleAnalyticsCategory } from '../../../../models/dtos/enums';
import { LoansApi } from '../../../../apis/loansApi';
import NewLoanSelectorMini from '../eligible/newLoanSelectorMini';
import { LoanStore } from '../../../../stores/loanStore';
import NewLoanSelectorMiniSimple from '../eligible/newLoanSelectorMiniSimple';
import { FeatureFlagApi } from '../../../../apis/featureFlagApi';

export interface OutstandingLoanWidgetProps extends ChildWidgetProps {
  loans: ILoanDto[];
  onViewLoanHistory(): void;
  borrowerApi: BorrowerApi;
  loansApi: LoansApi;
  loanStore: LoanStore;
  onGetLoan(): void;
  featureFlagApi: FeatureFlagApi;
}

export interface OutstandingLoanWidgetState {
  active: boolean;
  borrower: IBorrowerDto;
  outstandingLoanBalance: OutstandingLoanBalanceDto;
  isCreditLine: boolean;
  loanRanges: LoanRangesDto;
  sliderValue: number;
  decrementDisabled: boolean;
  incrementDisabled: boolean;
  errorStatus: number;
  errorMsg: string;
}

class OutstandingLoanWidget extends React.Component<
  OutstandingLoanWidgetProps,
  OutstandingLoanWidgetState
> {
  constructor(props: OutstandingLoanWidgetProps) {
    super(props);

    this.props.widgetStore.setMenuVisibility(true);
  }

  interval: NodeJS.Timeout;
  state = {
    active: false,
    borrower: undefined as IBorrowerDto,
    outstandingLoanBalance: undefined as OutstandingLoanBalanceDto,
    isCreditLine: false,
    loanRanges: {
      minimumAmount: 0,
      maximumAmount: 0,
      paymentTerms: [],
    },
    sliderValue: 0,
    decrementDisabled: true,
    incrementDisabled: true,
    errorStatus: 0,
    errorMsg: '',
  };

  async componentDidMount() {
    try {
      const borrower = await this.props.borrowerApi.getBorrower();
      const outstandingLoanBalance = await this.props.loansApi.getOutstandingLoanBalance();
      const creditLineFlag = await this.props.featureFlagApi.getFeatureFlag(
        FeatureFlagEnum.InstaCashCreditLine
      );
      let creditLineFlagValue = (creditLineFlag.keyValue !== null && creditLineFlag.keyValue !== undefined)? creditLineFlag.keyValue:false;
      this.props.widgetStore.setCreditLineFlag(creditLineFlagValue);
      const isCreditLine = this.props.widgetStore.isCreditLine;
      let loanRanges: LoanRangesDto = {
        minimumAmount: 0,
        maximumAmount: 0,
        paymentTerms: [],
      };

      await this.props.loansApi
        .getLoanRanges()
        .then(newLoanRanges => {
          loanRanges = newLoanRanges;
        })
        .catch(ex => {
          let errorStatus = ex.response.status;
          let errorMsg = ex.response.data;

          loanRanges = {
            minimumAmount: 0,
            maximumAmount: 0,
            paymentTerms: [],
          };

          this.setState({ ...this.state, errorStatus, errorMsg });
        });
      const maxLoanAmount = loanRanges.maximumAmount;
      this.setState(
        {
          ...this.state,
          borrower,
          outstandingLoanBalance,
          isCreditLine,
          loanRanges,
          sliderValue: loanRanges.minimumAmount,
        },
        () => {
          this.props.onFinished();
          const ms = 500 / (maxLoanAmount / 1000);
          this.interval = setInterval(() => {
            if (this.state.sliderValue < maxLoanAmount)
              this.setState({
                ...this.state,
                sliderValue: this.state.sliderValue + 1000,
              });
            else {
              this.setState({
                ...this.state,
                decrementDisabled: false,
              });
              clearInterval(this.interval);
            }
          }, ms);
        }
      );
      this.props.onFinished();
    } catch {
      this.props.onError();
    }
  }

  handleOnViewLoanHistory = () => {
    event({
      category: GoogleAnalyticsCategory.LoanRequestedWidget,
      action: 'Click View my History',
    });
    this.props.onViewLoanHistory();
  };

  handleOnSliderChange = (sliderValue: number) => {
    const decrementDisabled = sliderValue <= this.state.loanRanges.minimumAmount;
    const incrementDisabled = sliderValue >= this.state.loanRanges.maximumAmount;
    this.setState({
      ...this.state,
      sliderValue,
      decrementDisabled,
      incrementDisabled,
    });
  };

  handleOnDecrementClick = () => {
    const sliderValue = this.state.sliderValue - 1000;
    const decrementDisabled = sliderValue <= this.state.loanRanges.minimumAmount;

    this.setState({
      ...this.state,
      sliderValue,
      decrementDisabled,
      incrementDisabled: false,
    });
  };

  handleOnIncrementClick = () => {
    const sliderValue = this.state.sliderValue + 1000;
    const incrementDisabled = sliderValue >= this.state.loanRanges.maximumAmount;

    this.setState({
      ...this.state,
      sliderValue,
      decrementDisabled: false,
      incrementDisabled,
    });
  };

  handleOnSubmit = () => {
    this.props.loanStore.setMaximumLoanAmount(this.state.loanRanges.maximumAmount);
    this.props.loanStore.setLoanAmount(this.state.sliderValue);
    this.props.onGetLoan();
    localStorage.setItem('selectedAmount', this.state.sliderValue.toString());
    localStorage.setItem('loanRanges', JSON.stringify(this.state.loanRanges));

    event({
      category: GoogleAnalyticsCategory.EligibleWidget,
      action: 'Click Get Cash',
      label: this.state.sliderValue.toString(),
    });
  };

  render() {
    const {
      active,
      borrower,
      outstandingLoanBalance,
      loanRanges,
      sliderValue,
      decrementDisabled,
      incrementDisabled,
    } = this.state;
    const maxLoanAmount = loanRanges.maximumAmount;
    const minLoanAmount = loanRanges.minimumAmount;
    const minLoanError = 'The minimum loan amount required is not reached.';
    const contactMsg = 'If you have any concerns, feel free to contact ';
    const emailText = ' experts@sprout.ph.';
    const warningIcon = <Warning size={24} weight="fill" />;
    const infoIcon = <Info size={24} weight="fill" />;

    let sliderHidden = true;
    let headerText = 'You have an outstanding balance';
    let bodyText = 'Whether you need it once or several times, you can apply for a salary advance and get the cash instantly!';
    let subText = '';
    let email = '';
    let icon = infoIcon;
    let iconStyle = styles.iconInformation;

    if (this.state.isCreditLine) {
      headerText = 'You have an outstanding balance';
      bodyText = 'Whether you need it once or several times, you can apply for a salary advance and get the cash instantly!';
      sliderHidden = false;

      if (this.state.errorStatus >= 400 && this.state.errorMsg !== minLoanError) {
        headerText = "Get Cash isn't available right now...";
        bodyText = "ReadyCash seems to be down. Don't worry as you can still view your outstanding balance below.";
        subText = contactMsg;
        icon = warningIcon;
        email = emailText;
        iconStyle = styles.iconWarning;
        sliderHidden = true;
      }
      if (this.state.errorMsg === minLoanError) {
        headerText = 'You have low credit right now';
        bodyText = "You need to have at least PHP 3,000.00 minimum credit to request a cash advance.";
        icon = infoIcon;
        iconStyle = styles.iconError;
        sliderHidden = true;
      }
    }

    return (
      <div className={styles.widget}>
        <div className={styles.content}>
          <AccordionContainer>
            <SimpleAccordion
              header={headerText}
              headerClassName={styles.title}
              bodyClassName={`${styles.body} ${styles.inner}`}
              iconClassName={iconStyle}
              className={`${styles.container} ${styles.accordion}`}
              active={!active}
              icon={icon}
            >
              <div>
                <p className={`${subText === '' ? 'mb-0' : ''} ${styles.desc}`}>
                  {bodyText}
                </p>
                <p className={`mb-0 ${subText !== '' ? styles.desc : ''}`}>
                  {subText}
                  <span>{email}</span>
                </p>
              </div>
            </SimpleAccordion>
            <div className={!sliderHidden ? styles.bottom : ''}>
              <SimpleAccordion
                header={'OUTSTANDING BALANCE'}
                headerClassName={styles.subTitle}
                bodyClassName={`${styles.body} ${styles.inner}`}
                className={`${styles.container} ${styles.accordion}`}
                active={active}
              >
                <div className="pt-1">
                  <OutstandingLoanContent
                    loan={this.props.loans[0]}
                    borrower={borrower}
                    loanBalance={outstandingLoanBalance}
                    onViewLoanHistory={this.props.onViewLoanHistory}
                  />
                </div>
              </SimpleAccordion>
            </div>
          </AccordionContainer>
        </div>
        <div className={styles.slider} hidden={sliderHidden}>
          {/* logic, if FF CreditLine is on and sufficient balance then display slider and additional logic if max and min is same then display the 
          LoanSelectorMiniSimple else LoanSelectorMini  */}
          {this.state.isCreditLine && maxLoanAmount === minLoanAmount ? (
            <NewLoanSelectorMiniSimple
              value={sliderValue}
              onSubmit={this.handleOnSubmit}
            />
          ) : (
            <NewLoanSelectorMini
              min={minLoanAmount}
              max={maxLoanAmount}
              value={sliderValue}
              onSliderChange={this.handleOnSliderChange}
              onDecrementClick={this.handleOnDecrementClick}
              onIncrementClick={this.handleOnIncrementClick}
              decrementDisabled={decrementDisabled}
              incrementDisabled={incrementDisabled}
              onSubmit={this.handleOnSubmit}
            />
          )}
        </div>
      </div>
    );
  }
}

export default OutstandingLoanWidget;

import { CompanySettingDto } from '../models/dtos/company';
import { AxiosApiBase } from './axiosApiBase';

export class CompanyApi {
  private axios = new AxiosApiBase();
  private baseUrl = '/api/v1/company';

  getCompanySetting(): Promise<CompanySettingDto> {
    return this.axios.get(`${this.baseUrl}/setting`);
  }
}

import React, { FC, useState, useEffect } from 'react';
import styles from './simpleAccordion.module.scss';
import { CaretDown } from 'phosphor-react';

export interface AccordionItemProps {
  active?: boolean;
  className?: string;
  header: React.ReactNode;
  headerClassName?: string;
  bodyClassName?: string;
  iconClassName?: string;
  icon?: React.ReactElement;
}

const SimpleAccordion: FC<AccordionItemProps> = ({
  header,
  children,
  headerClassName,
  bodyClassName,
  iconClassName,
  className,
  active,
  icon,
}) => {
  const [isOpen, setOpen] = useState(false);

  // Open section by default
  useEffect(() => {
    if (!active) {
      setOpen(isOpen => !isOpen);
    }
  }, [active]);

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={`${styles.header}`} onClick={() => setOpen(!isOpen)}>
        <div className={`${styles.headerContent} ${headerClassName}`}>
          <span className={iconClassName}>{icon}</span>
          {header}
        </div>
        <div className={styles.headerBg}>
          <CaretDown
            className={`${styles.headerControl} ${isOpen && [styles.invertControl]}`}
            size={16}
          />
        </div>
      </div>
      <div className={`${styles.body} ${isOpen && [styles.open]}`}>
        <div className={`${styles.inner} ${bodyClassName}`}>{children}</div>
      </div>
    </div>
  );
};
export default SimpleAccordion;

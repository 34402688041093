import { HubConnectionBuilder, HubConnection, LogLevel } from '@microsoft/signalr';

export class HubService {
  private hubConnection: HubConnection;

  constructor() {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl('signalR/widgetHub')
      .configureLogging(LogLevel.Error)
      .withAutomaticReconnect()
      .build();

    this.hubConnection.start().catch(err => console.log(err));
  }

  onUpdateWidget(callback: (borrowerId: number, widgetState: number) => void) {
    if (!callback) return;

    this.hubConnection.on('UpdateWidget', callback);
  }
}

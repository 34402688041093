import { Step } from './../components/_common/steppers/steps/step';
import { observable, action, computed } from 'mobx';

export interface PageStep {
  step: Step;
  active: boolean;
  location: string;
}

export class PageStepperStore {
  @observable
  private _pageSteps: PageStep[] = [
    {
      step: {
        number: 1,
        label: 'Amount',
      },
      active: true,
      location: '/loan-amount-selection',
    },
    {
      step: {
        number: 2,
        label: 'Purpose',
      },
      active: false,
      location: '/reason',
    },
    {
      step: {
        number: 3,
        label: 'Review & Submit',
      },
      active: false,
      location: '/agreement',
    },
  ];

  @computed
  get pageSteps() {
    return this._pageSteps;
  }

  @action
  setActiveIndex(index: number) {
    this._pageSteps.forEach((step, idx) => {
      step.active = false;
      if (index === idx) step.active = true;
    });
  }
}

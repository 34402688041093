import React, { FC } from 'react';
import styles from './header.module.scss';
import OverflowMenu from '../../../_common/overflowMenu';
import ReadyCashIcon from '../../../_common/logo/readycashIcon';
import InstaCashLogoV2 from '../../../_common/logo/instacashV2';
import { WidgetStore } from '../../../../stores/widgetStore';
import { event } from '../../../../utils/tracking';
import { GoogleAnalyticsCategory } from '../../../../models/dtos/enums';

export interface WidgetHeaderProps {
  widgetStore: WidgetStore;
  onViewLoanHistory(): void;
  widgetState: string;
}

const WidgetHeader: FC<WidgetHeaderProps> = ({
  widgetStore,
  onViewLoanHistory,
  widgetState,
}) => {
  const menuItems = ['View My History'];
  return (
    <div className={styles.header}>
      <div className={styles.logo}>
        <ReadyCashIcon />
      </div>
      {widgetStore.menuShowing && (
        <span className={styles.overflow}>
          <OverflowMenu
            widgetState={widgetState}
            onClick={item => {
              if (item === 'View My History') {
                onViewLoanHistory();
                event({
                  category: GoogleAnalyticsCategory.WidgetOverflowMenu,
                  action: 'Click View my History',
                });
              }
            }}
            menuItems={menuItems}
          />
        </span>
      )}
    </div>
  );
};

export default WidgetHeader;

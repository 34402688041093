import React, { FC } from 'react'
import { format as formatDate } from 'date-fns';

export enum DateTimeDisplayFormat {
  Month_d_yyyy = 'MMMM d, yyyy', // May 1, 2019
  Month_dd_yyyy = 'MMMM dd, yyyy', // May 01, 2019
  Mon_d_yyyy = 'MMM d, yyyy', // Jan 13, 2020
  Month_dd = 'MMMM dd', // May 01
}

export interface DateTimeDisplayProps {
  format: DateTimeDisplayFormat;
  value: string;
}

const DateTimeDisplay: FC<DateTimeDisplayProps> = ({ format, value }) => {
  const getFormatted = () => {
    try {
      return formatDate(new Date(value), format);
    } catch {
      return 'Invalid Date';
    }
  };

  return <React.Fragment>{getFormatted()}</React.Fragment>;
};

export default DateTimeDisplay;

import React from 'react';
import './app.scss';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import 'sprout-mdbreact/dist/css/main.css';
import { HashRouter } from 'react-router-dom';
import { router } from './utils/router';
import { Provider, observer } from 'mobx-react';
import { AuthStore } from './stores/authStore';
import { LoanStore } from './stores/loanStore';
import { AuthApi } from './apis/authApi';
import { LoansApi } from './apis/loansApi';
import { BorrowerApi } from './apis/borrowerApi';
import { AdminApi } from './apis/adminApi';
import { AppStore } from './stores/appStore';
import { ReasonStore } from './stores/reasonStore';
import { PageStepperStore } from './stores/pageStepperStore';
import { DocumentsApi } from './apis/documentsApi';
import { WidgetApi } from './apis/widgetApi';
import { HubService } from './services/hubService';
import { BorrowerStore } from './stores/borrowerStore';
import { WidgetStore } from './stores/widgetStore';
import { CompanyApi } from './apis/companyApi';
import { isJSON } from './utils/helpers';
import { AppTokenHeaders, HRData } from './models/dtos/auth';
import { FeatureFlagApi } from './apis/featureFlagApi';

interface Props {}

interface State {
  authStore: AuthStore;
  loanStore: LoanStore;
  authApi: AuthApi;
  loansApi: LoansApi;
  borrowerApi: BorrowerApi;
  adminApi: AdminApi;
  documentsApi: DocumentsApi;
  widgetApi: WidgetApi;
  appStore: AppStore;
  reasonStore: ReasonStore;
  pageStepperStore: PageStepperStore;
  hubService: HubService;
  borrowerStore: BorrowerStore;
  widgetStore: WidgetStore;
  companyApi: CompanyApi;
  featureFlagApi: FeatureFlagApi;
}

@observer
class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    window.addEventListener(
      'message',
      e => {
        if (window.location.origin === e.origin || !e.data || !isJSON(e.data)) {
          return;
        }

        const jsonToken: HRData = JSON.parse(e.data);
        if (!jsonToken.type || jsonToken.type !== 'access token') {
          return;
        }
        localStorage.setItem('token', jsonToken.value);
        localStorage.setItem('pendoParams', jsonToken.pendoParams);
        var headers = jsonToken.headers;
        const parseHeaders: AppTokenHeaders = JSON.parse(headers);
        localStorage.setItem('header.clientid', parseHeaders.clientId);
        localStorage.setItem('header.employeeid', parseHeaders.employeeId);
        localStorage.setItem('header.companyid', parseHeaders.companyId);
        localStorage.setItem('header.hrdomain', parseHeaders.hrDomain);
        localStorage.setItem('header.payrollDb', parseHeaders.payrollDb);
        localStorage.setItem('header.hrDatabase', parseHeaders.hrDatabase);
      },
      false
    );

    this.state = {
      authStore: new AuthStore(),
      loanStore: new LoanStore(),
      authApi: new AuthApi(),
      loansApi: new LoansApi(),
      borrowerApi: new BorrowerApi(),
      adminApi: new AdminApi(),
      documentsApi: new DocumentsApi(),
      widgetApi: new WidgetApi(),
      appStore: new AppStore(),
      reasonStore: new ReasonStore(),
      pageStepperStore: new PageStepperStore(),
      hubService: new HubService(),
      borrowerStore: new BorrowerStore(),
      widgetStore: new WidgetStore(),
      companyApi: new CompanyApi(),
      featureFlagApi: new FeatureFlagApi()
    };
  }

  render() {
    const {
      loansApi,
      authStore,
      borrowerApi,
      authApi,
      loanStore,
      adminApi,
      documentsApi,
      widgetApi,
      appStore,
      reasonStore,
      pageStepperStore,
      hubService,
      borrowerStore,
      widgetStore,
      companyApi,
      featureFlagApi
    } = this.state;
    return (
      <div className="white-skin">
        <Provider
          loansApi={loansApi}
          authStore={authStore}
          borrowerApi={borrowerApi}
          authApi={authApi}
          loanStore={loanStore}
          adminApi={adminApi}
          documentsApi={documentsApi}
          widgetApi={widgetApi}
          appStore={appStore}
          reasonStore={reasonStore}
          pageStepperStore={pageStepperStore}
          hubService={hubService}
          borrowerStore={borrowerStore}
          widgetStore={widgetStore}
          companyApi={companyApi}
          featureFlagApi={featureFlagApi}
        >
          <HashRouter>{router()}</HashRouter>
        </Provider>
      </div>
    );
  }
}

export default App;

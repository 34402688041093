export const dependencies: string[] = [
  'authStore',
  'loanStore',
  'appStore',
  'reasonStore',
  'pageStepperStore',
  'loansApi',
  'authApi',
  'borrowerApi',
  'adminApi',
  'documentsApi',
  'widgetApi',
  'borrowerStore',
  'hubService',
  'widgetStore',
  'companyApi',
  'featureFlagApi'
];

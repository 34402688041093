import React from 'react';
import clsx from 'clsx';
import styles from './accordionContainer.module.scss';

export interface AccordionContainerProps {
  className?: string;
}

/**
 * Component to wrap accordion items
 */
const AccordionContainer: React.FC<AccordionContainerProps> = ({
  children,
  className,
  ...otherProps
}) => {
  return (
    <div className={clsx(styles.accordionContainer, className)} {...otherProps}>
      {children}
    </div>
  );
};

export default AccordionContainer;

export enum BorrowerFileType {
  GovernmentId = 'GovernmentId',
}

export enum LoanDocumentType {
  PromissoryNote = 'PromissoryNote',
  AuthorizationForDeduction = 'AuthorizationForDeduction',
  DisclosureStatement = 'DisclosureStatement',
  DataPrivacyDisclosureAndConsent = 'DataPrivacyDisclosureAndConsent',
  SproutTermsAndConditions = 'SproutTermsAndConditions',
  InstaCashDataPrivacyAndConsent = 'InstaCashDataPrivacyAndConsent',
}

export enum LoanStatus {
  Requested = 'Requested',
  Disbursed = 'Disbursed',
  Paid = 'Paid',
  Delinquent = 'Delinquent',
  Cancelled = 'Cancelled'
}

export enum LoanPaymentStatus {
  Pending = 'Pending',
  Deferred = 'Deferred',
  Deducted = 'Deducted',
  Paid = 'Paid',
  Cancelled = 'Cancelled'
}

export enum LoanPaymentStatusBadgeColor {
  PENDING = 'warning',
  DEFERRED = 'danger',
  DEDUCTED = 'success',
  PAID = 'success',
  CANCELLED = 'default'
}

export enum GoogleAnalyticsCategory {
  AgreementToDpaConsent = 'Agreement To DPA Consent',
  LoanAmountSelection = 'Loan Amount Selection',
  UploadID = 'Upload ID',
  AgreementToContracts = 'Agreement to Contracts',
  LoanApplicationSuccessful = 'Loan Application Successful',
  LoanHistory = 'Loan History',
  ReasonForBorrowing = 'Reason for Borrowing',
  PendingTransactions = 'Pending Transactions',

  WidgetOverflowMenu = 'Widget Overflow Menu',
  BorrowerNotEligibleWidget = 'Borrower Not Eligible Widget',
  EligibleWidget = 'Borrower Eligible Widget',
  OutstandingLoanWidget = 'Outstanding Loan Widget',
  SystemUnderMaintenanceWidget = 'System Under Maintenance Widget',
  ErrorWidget = 'Error Widget',
  LoanRequestedWidget = 'Loan Requested Widget',
  UnconsentedWidget = 'Marketing Widget',
  ConsentAgreementWidget = 'DPA Consent Agreement Widget',
  BenefitsAdminWidget = 'Benefits Admin Widget',
  BenefitsEmployeeWidget = 'Benefits Employee Widget',
  UnauthorizedWidget = 'Unauthorized Widget',
}

export enum LoanReason {
  Educational = 'Educational',
  Medical = 'Medical',
  RentMortgage = 'Rent/Mortgage',
  Travel = 'Travel',
  Utilities = 'Utilities',
  Others = 'Others',
}

export enum WidgetStateEnum {
  BorrowerNotEligible = 'BorrowerNotEligible',
  CompanyNotEligible = 'CompanyNotEligible',
  Eligible = 'Eligible',
  OutstandingLoan = 'OutstandingLoan',
  EmploymentStatusChanged = 'EmploymentStatusChanged',
  SystemUnderMaintenance = 'SystemUnderMaintenance',
  Error = 'Error',
  LoanRequested = 'LoanRequested',
  Unconsented = 'Unconsented',
  ConsentAgreement = 'ConsentAgreement',
  BenefitsAdmin = 'BenefitsAdmin',
  BenefitsEmployee = 'BenefitsEmployee',
  Unauthorized = 'Unauthorized',
}

export enum FeatureFlagEnum{
  AutoDeductionForAdvance ='AutoDeductionForAdvance',
  AdvanceUndeductedLoans ='AdvanceUndeductedLoans',
  FlexibleLoans ='FlexibleLoans' ,
  AdvanceCreditCapDisabled ='AdvanceCreditCapDisabled',
  InstaCashForProbi ='InstaCashForProbi',
  Reloan ='Reloan',
  InstaCashCreditLine ='InstaCashCreditLine',
  InstaCashNewArchitecture='InstaCashNewArchitecture'
}

export enum LoanStatusCategory{
  Active='Active',
  Complete='Complete',
  Ongoing='Ongoing'
}
 





import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './app';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';

const gaId =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_GOOGLE_ANALYTICS_ID_PROD
    : process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

ReactGA.initialize(gaId, {
  debug: false, //process.env.REACT_APP_ENVIRONMENT !== 'production',
  titleCase: false,
  gaOptions: { cookieDomain: 'auto' },
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { observable, computed, action } from 'mobx';
import { ILoanQuoteDto, ILoanDto, LoanRangesDto, OutstandingLoanBalanceDto } from '../models/dtos/loan';
import { IPagedResultDto } from '../models/dtos/pagedResult';

export class LoanStore {
  @observable
  private loanQuote: ILoanQuoteDto = {
    loanAmount: 3000,
    interest: 0,
    convenienceFee: 0,
    paymentPerPeriod: 0,
    total: 0,
    dueDates: [],
    referenceId: '',
    loanDocuments: [],
  };

  @observable
  private _maximumLoanAmount: number = 0;

  @observable
  loanRequested: ILoanDto | undefined;

  @action
  setLoanQuote(loanQuote: ILoanQuoteDto) {
    this.loanQuote = loanQuote;
  }

  @observable
  termNumber: number = 0;

  @action
  setTermNumber(term: number) {
    this.termNumber = term;
  }

  @action
  resetLoanQuote() {
    this.setLoanQuote({
      loanAmount: 3000,
      interest: 0,
      convenienceFee: 0,
      paymentPerPeriod: 0,
      total: 0,
      dueDates: [],
      referenceId: '',
      loanDocuments: [],
    });
    this.resetMaximumLoanAmount();
  }

  @computed
  get hasLoanQuote() {
    return this.loanQuote !== undefined && this.loanQuote.referenceId !== '';
  }

  @computed
  get currentLoanQuote() {
    return this.loanQuote;
  }

  @action
  setLoanRequested(loan: ILoanDto) {
    this.loanRequested = loan;
  }

  @action
  setMaximumLoanAmount(amount: number) {
    this._maximumLoanAmount = amount;
  }

  @action
  resetMaximumLoanAmount() {
    this.setMaximumLoanAmount(0);
  }

  @action
  setLoanAmount(amount: number) {
    this.loanQuote.loanAmount = amount;
  }

  @computed
  get maximumLoanAmount(): number {
    return this._maximumLoanAmount;
  }

  /**
   * Loan History
   */
  @observable
  // private _history: ILoanDto[] = [];
  private _history: IPagedResultDto<ILoanDto[]>;

  
  defaultLoanHistory() {
    return {
      data:[],
      pagination: {
        currentPage:0,
        itemsPerPage:10,
        totalItems:0,
        totalPages:0,
        links:{ 
          last:"",
          next:"",
          previous: ""
        }
      }
    };
  } 

  @action
  // setLoanHistory(history: ILoanDto[]) {
  //   this._history = history;
  // } 
  setLoanHistory(history: IPagedResultDto<ILoanDto[]>) {
    this._history = history; 
  }

  @computed
  get loanHistory() { 
    return this._history ? this._history : this.defaultLoanHistory()  ;
  }

  @observable
  private _loanRanges: LoanRangesDto;

  @action
  setLoanRanges(loanRanges: LoanRangesDto) {
    this._loanRanges = loanRanges;
  }

  @action
  resetLoanRanges() {
    this._loanRanges = undefined;
  }

  @computed
  get loanRanges() {
    return this._loanRanges;
  }

  @computed
  get maxPaymentTerm() {
    return this._loanRanges ? this._loanRanges.paymentTerms.sort((a, b) => b - a)[0] : 0;
  }
  
}

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { pageView } from '../utils/tracking';

const withTracker = WrappedComponent => {
  const trackPage = page => {
    pageView(page);
  };

  const Tracker = class TrackerComponent extends React.Component<RouteComponentProps> {
    componentDidMount() {
      trackPage(this.props.location.pathname);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return Tracker;
};

export default withTracker;

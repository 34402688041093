import * as React from 'react';
import styles from './eligible.module.scss';
import clsx from 'clsx';
import NewLoanSelectorMini from './newLoanSelectorMini';
import NewLoanSelectorMiniSimple from './newLoanSelectorMiniSimple';
import { LoanStore } from '../../../../stores/loanStore';
import { ChildWidgetProps } from '..';
import { LoansApi } from '../../../../apis/loansApi';
import { LoanRangesDto } from '../../../../models/dtos/loan';
import { event } from '../../../../utils/tracking';
import { GoogleAnalyticsCategory } from '../../../../models/dtos/enums';

export interface EligibleWidgetProps extends ChildWidgetProps {
  loansApi: LoansApi;
  loanStore: LoanStore;
  onGetLoan(): void;
}

export interface EligibleWidgetState {
  value: number;
  decrementDisabled: boolean;
  incrementDisabled: boolean;
  loanRanges: LoanRangesDto;
}

export class EligibleWidget extends React.Component<
  EligibleWidgetProps,
  EligibleWidgetState
> {
  constructor(props: EligibleWidgetProps) {
    super(props);

    this.props.widgetStore.setMenuVisibility(true);
  }

  interval: NodeJS.Timeout;
  state = {
    value: 0,
    decrementDisabled: true,
    incrementDisabled: true,
    loanRanges: {
      minimumAmount: 0,
      maximumAmount: 0,
      paymentTerms: [],
    },
  };

  async componentDidMount() {
    try {
      const loanRanges = await this.props.loansApi.getLoanRanges();
      const maxLoanAmount = loanRanges.maximumAmount;
      this.setState(
        {
          ...this.state,
          value: loanRanges.minimumAmount,
          loanRanges,
        },
        () => {
          this.props.onFinished();
          const ms = 500 / (maxLoanAmount / 1000);
          this.interval = setInterval(() => {
            if (this.state.value < maxLoanAmount)
              this.setState({
                ...this.state,
                value: this.state.value + 1000,
              });
            else {
              this.setState({
                ...this.state,
                decrementDisabled: false,
              });
              clearInterval(this.interval);
            }
          }, ms);
        }
      );
    } catch {
      this.props.onError();
    }
  }

  handleOnSliderChange = (value: number) => {
    const decrementDisabled = value <= this.state.loanRanges.minimumAmount;
    const incrementDisabled = value >= this.state.loanRanges.maximumAmount;
    this.setState({
      ...this.state,
      value,
      decrementDisabled,
      incrementDisabled,
    });
  };

  handleOnDecrementClick = () => {
    const value = this.state.value - 1000;
    const decrementDisabled = value <= this.state.loanRanges.minimumAmount;

    this.setState({
      ...this.state,
      value,
      decrementDisabled,
      incrementDisabled: false,
    });
  };

  handleOnIncrementClick = () => {
    const value = this.state.value + 1000;
    const incrementDisabled = value >= this.state.loanRanges.maximumAmount;

    this.setState({
      ...this.state,
      value,
      decrementDisabled: false,
      incrementDisabled,
    });
  };

  handleOnSubmit = () => {
    this.props.loanStore.setMaximumLoanAmount(this.state.loanRanges.maximumAmount);
    this.props.loanStore.setLoanAmount(this.state.value);
    this.props.onGetLoan();
    localStorage.setItem('selectedAmount', this.state.value.toString());
    localStorage.setItem('loanRanges', JSON.stringify(this.state.loanRanges));

    event({
      category: GoogleAnalyticsCategory.EligibleWidget,
      action: 'Click Get Cash',
      label: this.state.value.toString(),
    });
  };

  render() {
    const { loanRanges, value, decrementDisabled, incrementDisabled } = this.state;
    const maxLoanAmount = loanRanges.maximumAmount;
    const minLoanAmount = loanRanges.minimumAmount;
    return (
      <div className={styles.widget}>
        <div className={clsx(styles.content, styles.grayScrollbar)}>
          <p className={styles.title}>Need Cash?</p>
          <div
            className={clsx(styles.bodyContent, {
              [styles.pad]: maxLoanAmount === minLoanAmount,
            })}
          >
            Get the support you need for your emergencies. Apply for a cash advance and
            receive it instantly.
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.loanSelector}>
            {maxLoanAmount === minLoanAmount ? (
              <NewLoanSelectorMiniSimple value={value} onSubmit={this.handleOnSubmit} />
            ) : (
              <NewLoanSelectorMini
                min={minLoanAmount}
                max={maxLoanAmount}
                value={value}
                onSliderChange={this.handleOnSliderChange}
                onDecrementClick={this.handleOnDecrementClick}
                onIncrementClick={this.handleOnIncrementClick}
                decrementDisabled={decrementDisabled}
                incrementDisabled={incrementDisabled}
                onSubmit={this.handleOnSubmit}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default EligibleWidget;

import { GetTokenRequest, TokenDto } from '../models/dtos/auth';
import { ApiBase } from './apiBase';
import { Observable } from 'rxjs';

export interface IAuthApi {
  getToken(request: GetTokenRequest): Promise<TokenDto>;
  ping(): Promise<{}>;
}

export class AuthApi extends ApiBase {
  private baseUrl = '/api/v1/auth';

  getToken(request: GetTokenRequest): Observable<TokenDto> {
    return this.post<TokenDto>(`${this.baseUrl}/token`, request);
  }

  ping() {
    return this.get(`${this.baseUrl}/ping`);
  }
}

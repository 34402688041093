import { IBorrowerDto } from '../models/dtos/borrower';
import { BorrowerFileType } from '../models/dtos/enums';
import { AxiosApiBase } from './axiosApiBase';
import { ProgressStore } from '../stores/progressStore';

export class BorrowerApi extends AxiosApiBase {
  private baseUrl = '/api/v1/borrower';

  getBorrower(): Promise<IBorrowerDto> {
    return this.get<IBorrowerDto>(`${this.baseUrl}/details`);
  }

  hasUploadedIds(): Promise<boolean> {
    return this.get<boolean>(`${this.baseUrl}/hasUploadedIds`);
  }

  uploadDocument(
    borrowerFileType: BorrowerFileType,
    data: FormData,
    progressStore?: ProgressStore
  ): Promise<{}> {
    let config = {};
    if (progressStore) {
      config = {
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          progressStore.setPercentage(percentCompleted);
        },
      };
    }
    return this.post(`${this.baseUrl}/documents/${borrowerFileType}`, data, config);
  }

  getMaximumAllowableLoanAmount() {
    return this.get<number>(`${this.baseUrl}/loan/maximumAmount`);
  }

  getDpaConsent() {
    return this.get<boolean>(`${this.baseUrl}/consent`);
  }

  updateDpaConsent(agreed: boolean) {
    return this.put<void>(`${this.baseUrl}/consent/${agreed}`);
  }
}

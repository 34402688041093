import * as React from 'react';
import styles from './overflowMenu.module.scss';
import { MDBBtn } from 'sprout-mdbreact';
import { DotsThreeVertical } from 'phosphor-react';

export interface OverflowMenuProps {
  menuItems: string[];
  onClick(itemName: string): void;
  widgetState: string;
}
export interface OverflowState {
  isDropdownVisible: boolean;
  dontBlur: boolean;
}

class OverflowMenu extends React.Component<OverflowMenuProps, OverflowState> {
  state = {
    isDropdownVisible: false,
    dontBlur: false,
    widgetState: '',
  };

  handleClickOverflowMenu = () => {
    this.setState({
      isDropdownVisible: !this.state.isDropdownVisible,
    });
  };

  handleOnBlur = () => {
    this.setState({
      ...this.state,
      isDropdownVisible: false,
    });
  };

  handleItemClick = (menu: string) => {
    this.setState({
      isDropdownVisible: false,
    });

    this.props.onClick(menu);
  };

  get menuItems() {
    return this.props.menuItems.map((menu, key) => (
      <div
        className={styles.item2}
        key={key}
        onClick={() => this.handleItemClick(menu)}
        onMouseDown={e => e.preventDefault()}
      >
        {menu}
      </div>
    ));
  }

  render() {
    const { isDropdownVisible } = this.state;
    return (
      <div className={styles.overflow}>
        <MDBBtn
          flat
          onClick={this.handleClickOverflowMenu}
          onBlur={this.handleOnBlur}
          className={`icon-btn primaryBtn ${styles.button} ${isDropdownVisible && [
            styles.buttonActive2,
          ]}`}
        >
          <DotsThreeVertical className={styles.icon} size={30} />
        </MDBBtn>
        {isDropdownVisible && <div className={styles.menu}> {this.menuItems}</div>}
      </div>
    );
  }
}

export default OverflowMenu;

import { event } from '../utils/tracking';

export class Parent {
  static closeModal(category: string) {
    this.AddEvent(category);
    window.parent.postMessage('onCancelLoan', '*');

    Parent.closeParentModal();
  }

  static closeModalOnLoanApplied(category: string) {
    this.AddEvent(category);
    window.parent.postMessage('onLoanApplied', '*');
    
    Parent.closeParentModal();
  }

  /** Inform parent modal (on this case HR) to open the modal */
  static openParentModal(route: string) {
    window.parent.postMessage(
      JSON.stringify({ type: 'instacash route', value: route }),
      '*'
    );
  }

  /** Inform parent modal (on this case HR) to close the modal */
  static closeParentModal() {
    window.parent.postMessage(
      JSON.stringify({ type: 'instacash action', value: 'onModalClose' }),
      '*'
    );
  }

  private static AddEvent(category: string) {
    event({
      category: category,
      action: 'Back to Dashboard',
    });
  }
}

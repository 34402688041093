import ReactGA from 'react-ga';

export const pageView = (page: string) => {
  ReactGA.ga('set', 'page', page);
  ReactGA.ga('send', 'pageview');
};

export const event = (props: ReactGA.EventArgs) => {
  ReactGA.ga('send', {
    hitType: 'event',
    eventCategory: props.category,
    eventAction: props.action,
    eventLabel: props.label,
  });
};

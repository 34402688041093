import { observable, computed, action } from 'mobx';

export class AppStore {
  @observable
  domain: string;

  @computed
  get sproutUri() {
    return `http://${this.domain}`;
  }

  @action
  setDomain(domain: string) {
    this.domain = domain;
  }
}

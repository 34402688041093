import Axios from 'axios';

export class AxiosApiBase {
  setDefaultHeaders() {
    const token = localStorage.getItem('token');
    const clientId = localStorage.getItem('header.clientid');
    const companyId = localStorage.getItem('header.companyid');
    const employeeId = localStorage.getItem('header.employeeid');
    const hrDomain = localStorage.getItem('header.hrdomain');
    const payrollDb = localStorage.getItem('header.payrollDb');
    const hrDatabase = localStorage.getItem('header.hrDatabase');
    Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    Axios.defaults.headers.common['x-clientid'] = clientId;
    Axios.defaults.headers.common['x-companyid'] = companyId;
    Axios.defaults.headers.common['x-hrdomain'] = hrDomain;
    Axios.defaults.headers.common['x-employeeid'] = employeeId;
    Axios.defaults.headers.common['x-payrolldatabase'] = payrollDb;
    Axios.defaults.headers.common['x-hrdatabasename'] = hrDatabase;
  }

  public async get<T>(url: string): Promise<T> {
    this.setDefaultHeaders();
    const response = await Axios.get(url);
    return response.data as T;
  }

  public async post<T>(url: string, data?: any, config?: any): Promise<T> {
    this.setDefaultHeaders();
    const response = await Axios.post(url, data, config);
    return response.data as T;
  }

  public async put<T>(url: string, data?: any, config?: any): Promise<T> {
    this.setDefaultHeaders();
    const response = await Axios.put(url, data, config);
    return response.data as T;
  }

  public async download<T>(url: string): Promise<T> {
    this.setDefaultHeaders();
    const response = await Axios.get(url, { responseType: 'blob' });
    return response.data as T;
  }
}

import * as React from 'react';
import styles from './listItem.module.scss';
import clsx from 'clsx';

export interface ListItemProps {
  label: React.ReactNode;
  value: React.ReactNode;
  separatorShowing?: boolean;
  className?: string;
  valueClassName?: string;
  subLabel?: string;
}

const ListItem: React.FC<ListItemProps> = ({
  label,
  value,
  separatorShowing = true,
  className = '',
  valueClassName = '',
  subLabel,
}) => {
  const { listItem, listItemLabel, listItemValue, wrapper, separator } = styles;

  return (
    <div className={wrapper}>
      <div className={clsx(listItem, className, { [separator]: separatorShowing })}>
        <div className={listItemLabel}>{label}<span>{subLabel}</span></div>
        <div className={clsx(valueClassName, listItemValue)}>{value}</div>
      </div>
    </div>
  );
};

export default ListItem;

import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ProtectedRoute from './protectedRoute';
import OutstandingLoanWidget from '../components/_pages/loanWidget/outstandingLoanDetailsWidget';
import UnauthorizeAccessPage from '../components/_pages/unauthorizeAccessPage';
import Home from '../components/_pages/home';
import withTracker from '../utils/withTracker';
import Widget from '../components/_pages/widget';
import PageProgress from '../components/_common/progress/page';

// Lazy-load non widget pages
const ReasonsPage = lazy(() => import('../components/_pages/reasons/reasonsPage'));
const Agreement = lazy(() => import('./../components/_pages/agreement/index'));
const LoanResult = lazy(() => import('../components/_pages/loanResult'));
const LoanHistory = lazy(() => import('../components/_pages/loanHistory'));
const LoanAmountSelection = lazy(() =>
  import('../components/_pages/loanAmountSelection')
);

export const router = () => {
  return (
    <Suspense fallback={<PageProgress />}>
      <Switch>
        <ProtectedRoute
          path="/loan-amount-selection"
          component={withTracker(LoanAmountSelection)}
        />
        <ProtectedRoute path="/reason" component={withTracker(ReasonsPage)} />
        <ProtectedRoute path="/agreement" component={withTracker(Agreement)} />
        <ProtectedRoute path="/loan-result" component={withTracker(LoanResult)} />
        <ProtectedRoute exact path="/loan-history" component={withTracker(LoanHistory)} />
        <ProtectedRoute
          path="/outstanding-loan-details-widget"
          component={withTracker(OutstandingLoanWidget)}
        />
        <Route path="/widget" component={withTracker(Widget)} />
        <Route path="/unauthorized" component={UnauthorizeAccessPage} />
        <Route path="/home/:display?/:token?" component={Home} />
        <Redirect from="/" to="/home" />
      </Switch>
    </Suspense>
  );
};

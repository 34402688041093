import React, { FC } from 'react'
import styles from './newLoanSelectorMiniSimple.module.scss';
import Amount from '../../../../_common/displays/amount';

export interface LoanSelectorMiniSimpleProps {
  value: number;
  onSubmit(): void;
}

const NewLoanSelectorMiniSimple: FC<LoanSelectorMiniSimpleProps> = ({
  value,
  onSubmit,
}) => {
  return (
    <div>
      <div className={styles.amountWrapper}>
        <div className={styles.text}>
          <span>Available Credit</span>
        </div>
        <Amount
          value={value}
          valueClassName={styles.amount}
          currencyClassName={styles.currency}
        />
      </div>
      <div className={styles.bottomWrapper}>
        <div className={styles.btnWrapper}>
          <button className={`${styles.button} primaryBtn`} onClick={onSubmit}>
            <span>Get Cash</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewLoanSelectorMiniSimple;

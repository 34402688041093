import React, { FC } from 'react';
import styles from './outstandingLoanContent.module.scss';
import NumericDisplay from '../../../../_common/displays/numeric/numericDisplay';
import DateTimeDisplay, {
  DateTimeDisplayFormat,
} from '../../../../_common/displays/dateTime';
import { IBorrowerDto } from '../../../../../models/dtos/borrower';
import { ILoanDto, OutstandingLoanBalanceDto } from '../../../../../models/dtos/loan';
import 'tippy.js/dist/tippy.css';
import CircleProgress from '../../../../_common/progress/circle/circleProgress';

export interface OutstandingLoanContentProps {
  loan: ILoanDto;
  borrower: IBorrowerDto;
  loanBalance: OutstandingLoanBalanceDto;
  onViewLoanHistory(): void;
}

const OutstandingLoanContent: FC<OutstandingLoanContentProps> = ({
  loanBalance,
  onViewLoanHistory,
}) => {
  if (typeof loanBalance === 'undefined')
    return (
      <div>
        <CircleProgress height={25} width={25} />
      </div>
    );

  const { dueDate, totalAmountDue } = loanBalance.payments[0];
  const daysBeforeDueDate = loanBalance.daysBeforeDueDate;
  const totalRemainingBalance = loanBalance.totalRemainingBalance;

  return (
    <>
      <div className={`card ${styles.card}`}>
        <div className={styles.cardHeaderWrapper}>
          <div className={`grid ${styles.grid}`}>
            <div className={`box ${styles.totalBalWrapper}`}>
              <span className={`mb-0 text-white ${styles.headTitle}`}>
                TOTAL REMAINING BALANCE
              </span>
              <span className={`mb-0 text-white ${styles.subTitle}`}>
                <span className={`text-white ${styles.currency}`}>PHP </span>
                <NumericDisplay
                  showComma={true}
                  decimalCount={2}
                  value={totalRemainingBalance}
                  className={styles.totalRemBalanceSpan}
                />
              </span>
            </div>
            <div className={`box ${styles.btnWrapper}`}>
              <button
                className={`${styles.expandedAction} blueOutlinedBtn`}
                onClick={onViewLoanHistory}
              >
                View Details
              </button>
            </div>
          </div>
        </div>

        <div className={`card-body ${styles.cardBody}`}>
          <div className={`grid ${styles.gridBody}`}>
            <div className={`boxBody ${styles.upcomingWrapper}`}>
              <span className={`mb-0 ${styles.title}`}>Upcoming Payment</span>
              <span className={`mb-0 ${styles.sub}`}>
                <span className={styles.currency}>PHP </span>
                <NumericDisplay
                  showComma={true}
                  decimalCount={2}
                  value={totalAmountDue}
                />
              </span>
            </div>
            <div className={`boxBody ${styles.dueWrapper}`}>
              <span className={`mb-0 ${styles.title}`}>
                Due in {daysBeforeDueDate} days
              </span>
              <span className={`mb-0 ${styles.sub}`}>
                <DateTimeDisplay
                  value={dueDate}
                  format={DateTimeDisplayFormat.Month_dd}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OutstandingLoanContent;

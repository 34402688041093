import { observable, action, computed } from 'mobx';
import { ReasonDto } from '../models/dtos/loan';

export class ReasonStore {
  @observable
  private _allReasons: ReasonDto[];

  @observable
  private _loanReason: ReasonDto;

  @observable
  private _otherReason: string;

  @action
  setReason(reason: ReasonDto) {
    this._loanReason = reason;
  }

  @computed
  get currentReason() {
    return this._loanReason;
  }

  @action
  setAllReasons(reasons: ReasonDto[]) {
    this._allReasons = reasons;
  }

  @computed
  get allReasons() {
    return this._allReasons;
  }

  @action
  setOtherReason(otherReason: string) {
    this._otherReason = otherReason;
  }

  @action
  clearOtherReason() {
    this._otherReason = undefined;
  }

  @computed
  get otherReason() {
    return this._otherReason;
  }
}

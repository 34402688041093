import * as React from 'react';
import styles from './plusMinusButton.module.scss';
import { Plus, Minus } from 'phosphor-react';

export interface PlusMinusButtonProps {
  isPlusButton: boolean;
  disabled?: boolean;
  onClick(): void;
  outlined?: boolean;
}

const NewPlusMinusButton: React.FunctionComponent<PlusMinusButtonProps> = ({
  isPlusButton,
  disabled,
  onClick,
}) => {
  const cName = `${styles.iconBtn}`;
  const plus = <Plus size={24} className="plus" />;
  const minus = <Minus size={24} className="minus" />;

  return (
    <button
      className={cName}
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
    >
      {isPlusButton ? plus : minus}
    </button>
  );
};

export default NewPlusMinusButton;

import * as React from 'react';
import clsx from 'clsx';
import styles from './unauthorized.module.scss';
import { Warning, CopySimple, Check } from 'phosphor-react';
import { ChildWidgetProps } from '..';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export interface UnauthorizedWidgetProps extends ChildWidgetProps {
  errorCode: string;
}

class UnauthorizedWidget extends React.Component<UnauthorizedWidgetProps> {
  componentDidMount() {
    this.props.onFinished();

    this.props.widgetStore.setMenuVisibility(false);
  }

  state = {
    buttonText: 'Copy Code',
    copied: false,
  };

  onCopy = () => {
    this.setState({
      buttonText: 'Copied!',
      copied: true,
    });
  };

  handleOnClick = () => {
    setTimeout(() => {
      this.setState({
        buttonText: 'Copy Code',
        copied: false,
      });
    }, 3000);
  };

  render() {
    return (
      <div className={styles.widget}>
        <div className={clsx(styles.content, styles.grayScrollbar)}>
          <p className={styles.title}>
            <Warning className={styles.icon} size={24} weight="fill" />
            It's not you, it's us.
          </p>
          <p className={styles.bodyText}>
            Something went wrong on our end, but we're working on it! In the
            meantime, you can try to:
          </p>
          <ul className={styles.list}>
            <li>Refresh this page</li>
            <li>Log out of Sprout HR and log in again</li>
            <li>
              Contact
              <span className={`font-weight-bold pl-1 ${styles.spanText}`}>
                experts@sprout.ph.
              </span>
            </li>
          </ul>
        </div>
        <div className={styles.footer}>
          <span>
            Error Code: <span className="font-weight-bold">{this.props.errorCode}</span>
          </span>
          <CopyToClipboard text={this.props?.errorCode} onCopy={this.onCopy}>
            <button
              className={
                this.state.copied ? styles.textBtn : `${styles.button} outlinedBtn`
              }
              onClick={this.handleOnClick}
            >
              <span>
                {this.state.buttonText}
                {this.state.copied ? (
                  <Check className={styles.iconBtn} size={16} />
                ) : (
                  <CopySimple className={styles.iconBtn} size="14" weight="fill" />
                )}
              </span>
            </button>
          </CopyToClipboard>
        </div>
      </div>
    );
  }
}

export default UnauthorizedWidget;

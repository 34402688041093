import * as React from 'react';
import styles from './widget.module.scss';
import EligibleWidget from './eligible';
import WidgetHeader from './header';
import CircleProgress from '../../_common/progress/circle/circleProgress';
import BorrowerNotEligibleWidget from './notEligible/borrower';
import Frame from '../../_common/frame';
import CompanyNotEligibleWidget from './notEligible/company';
import SystemMaintenanceWidget from './systemMaintenance';
import { observer, inject } from 'mobx-react';
import { WidgetApi } from '../../../apis/widgetApi';
import { BorrowerApi } from '../../../apis/borrowerApi';
import { AdminApi } from '../../../apis/adminApi';
import { DialogStore } from '../../../stores/dialogStore';
import { FeatureFlagEnum, WidgetStateEnum } from '../../../models/dtos/enums';
import { LoanStore } from '../../../stores/loanStore';
import { RouteComponentProps } from 'react-router-dom';
import {
  WidgetDto,
  NonEligibleWidgetDto,
  OutstandingLoanWidgetDto,
  BenefitsWidgetDto,
  convertNumberToWidgetStateEnum,
  convertWidgetStateToGACategory,
} from '../../../models/dtos/widget';
import { dependencies } from '../../../models/constants';
import OutstandingLoanWidget from './outstandingLoan';
import LoanRequestedWidget from './loanRequested';
import { HubService } from '../../../services/hubService';
import { BorrowerStore } from '../../../stores/borrowerStore';
import { WidgetStore } from '../../../stores/widgetStore';
import UnconsentedWidget from './unconsented';
import ConsentAgreement from './consentAgreement';
import { CompanyApi } from '../../../apis/companyApi';
import { Parent } from '../../../services/parentService';
import { DocumentsApi } from '../../../apis/documentsApi';
import UnauthorizedWidget from './unauthorized';
import { LoansApi } from '../../../apis/loansApi';
import { event } from '../../../utils/tracking';
import { FeatureFlagApi } from '../../../apis/featureFlagApi';

export interface ChildWidgetProps {
  onFinished(): void;
  onError(): void;
  widgetStore: WidgetStore;
}

export interface WidgetProps extends RouteComponentProps<{}> {
  widgetApi: WidgetApi;
  borrowerApi: BorrowerApi;
  adminApi: AdminApi;
  loanStore: LoanStore;
  hubService: HubService;
  borrowerStore: BorrowerStore;
  widgetStore: WidgetStore;
  companyApi: CompanyApi;
  documentsApi: DocumentsApi;
  loansApi: LoansApi;
  featureFlagApi: FeatureFlagApi;
}

export interface WidgetState {
  widget: WidgetDto;
  errorStatus: string;
}

@inject(...dependencies)
@observer
export class Widget extends React.Component<WidgetProps, WidgetState> {
  loadingStore = new DialogStore();
  featureFlagApi = new FeatureFlagApi();

  constructor(props: WidgetProps) {
    super(props);

    this.state = {
      widget: {
        state: WidgetStateEnum.Error,
      } as WidgetDto,
      errorStatus: '',
    };

    this.props.hubService.onUpdateWidget(this.onUpdateWidget.bind(this));
  }

  onUpdateWidget = async (borrowerId: number, state: number) => {
    const { borrowerStore } = this.props;
    if (!borrowerStore.hasBorrower) {
      await this.setBorrowerStoreValue();
    }
    if (borrowerStore.hasBorrower && borrowerStore.borrower.id === borrowerId) {
      const widget = convertNumberToWidgetStateEnum(state);

      // if backend returns outstanding loan widget,
      // reinitialize the widget so that we can get the response with
      // outstanding loan details
      if (widget === WidgetStateEnum.OutstandingLoan) {
        this.loadingStore.show();
        this.initialize();
      } else if (widget === WidgetStateEnum.LoanRequested) {
        this.setState({ widget: { state: widget } });
      }
    }
  };

  initialize = async () => {
    try {
      const widget = await this.props.widgetApi.getWidget();
      this.setState({ widget });
    } catch (ex) {
      const widgetState =
        ex.response.status === 401 ? WidgetStateEnum.Unauthorized : WidgetStateEnum.Error;
      const errorCode = ex.response.status;

      this.setState({ widget: { state: widgetState }, errorStatus: errorCode });
      this.loadingStore.hide();
    }
  };

  init = async () => {
    try {
      await this.props.companyApi.getCompanySetting();
      await this.props.borrowerApi.getBorrower();
      await this.props.featureFlagApi.getFeatureFlag(FeatureFlagEnum.InstaCashCreditLine);
      await this.props.loansApi.getLoanRanges();
    } catch (ex) {
      const errorCode = ex.response.status;
      this.setState({ errorStatus: errorCode });
    }
  };

  setBorrowerStoreValue = async () => {
    if (this.props.borrowerStore.borrower) return;
    try {
      const borrower = await this.props.borrowerApi.getBorrower();
      if (borrower) this.props.borrowerStore.setBorrower(borrower);
    } catch (error) {
      this.props.borrowerStore.setBorrower(undefined);
    }
  };

  async componentDidMount() {
    this.loadingStore.show();
    setTimeout(async () => {
      await Promise.all([this.initialize(), this.setBorrowerStoreValue()]);
    }, 2000);
    this.init();
  }

  handleOnFinished = () => {
    this.loadingStore.hide();
    event({
      category: convertWidgetStateToGACategory(this.state.widget.state),
      action: 'View',
    });
  };

  handleOnGetLoan = () => {
    Parent.openParentModal('loan-amount-selection');
  };

  handleOnRetry = async () => {
    this.loadingStore.show();
    await this.initialize();
    if (this.loadingStore.showing) this.loadingStore.hide();
  };

  handleOnError = () => {
    this.setState(
      {
        widget: { state: WidgetStateEnum.Error },
      },
      () => {
        this.loadingStore.hide();
        event({
          category: convertWidgetStateToGACategory(this.state.widget.state),
          action: 'View',
        });
      }
    );
  };

  handleViewLoanHistory = () => {
    Parent.openParentModal('loan-history');
    //this.props.history.push('loan-history');
  };

  get progress() {
    return (
      <div className={styles.progress}>
        <CircleProgress width={72} height={72} />
      </div>
    );
  }

  get widget() {
    switch (this.state.widget.state) {
      case WidgetStateEnum.Eligible:
        return (
          <EligibleWidget
            onFinished={this.handleOnFinished}
            loansApi={this.props.loansApi}
            loanStore={this.props.loanStore}
            onGetLoan={this.handleOnGetLoan}
            onError={this.handleOnError}
            widgetStore={this.props.widgetStore}
          />
        );
      case WidgetStateEnum.Error:
        return (
          <UnauthorizedWidget
            onFinished={this.handleOnFinished}
            onError={this.handleOnError}
            widgetStore={this.props.widgetStore}
            errorCode={this.state.errorStatus}
          />
        );
      case WidgetStateEnum.BorrowerNotEligible:
        const nonEligibleWidgetDto = this.state.widget as NonEligibleWidgetDto;
        return (
          <BorrowerNotEligibleWidget
            reasons={nonEligibleWidgetDto.nonEligibilityReasons}
            onFinished={this.handleOnFinished}
            onError={this.handleOnError}
            widgetStore={this.props.widgetStore}
            url={nonEligibleWidgetDto.url}
          />
        );
      case WidgetStateEnum.BenefitsAdmin:
      case WidgetStateEnum.BenefitsEmployee:
      case WidgetStateEnum.CompanyNotEligible:
        const dto = this.state.widget as BenefitsWidgetDto;
        return (
          <CompanyNotEligibleWidget
            onFinished={this.handleOnFinished}
            onError={this.handleOnError}
            widgetStore={this.props.widgetStore}
            url={dto.url}
            text={dto.text}
            buttonText={dto.buttonText}
            base64Header={dto.base64Header}
            base64Content={dto.base64Content}
            widgetState={this.state.widget.state}
          />
        );
      case WidgetStateEnum.SystemUnderMaintenance:
        return (
          <SystemMaintenanceWidget
            onFinished={this.handleOnFinished}
            onError={this.handleOnError}
            onRetry={this.handleOnRetry}
            widgetStore={this.props.widgetStore}
          />
        );
      case WidgetStateEnum.OutstandingLoan:
        return (
          <OutstandingLoanWidget
            borrowerApi={this.props.borrowerApi}
            loans={(this.state.widget as OutstandingLoanWidgetDto).outstandingLoans}
            loansApi={this.props.loansApi}
            onFinished={this.handleOnFinished}
            onError={this.handleOnError}
            onViewLoanHistory={this.handleViewLoanHistory}
            widgetStore={this.props.widgetStore}
            loanStore={this.props.loanStore}
            onGetLoan={this.handleOnGetLoan}
            featureFlagApi={this.props.featureFlagApi}
          />
        );
      case WidgetStateEnum.LoanRequested:
        return (
          <LoanRequestedWidget
            onFinished={this.handleOnFinished}
            onError={this.handleOnError}
            onViewLoanHistory={this.handleViewLoanHistory}
            widgetStore={this.props.widgetStore}
          />
        );
      case WidgetStateEnum.Unconsented:
        return (
          <UnconsentedWidget
            widgetStore={this.props.widgetStore}
            companyApi={this.props.companyApi}
            onFinished={this.handleOnFinished}
            onError={this.handleOnError}
            onClick={() => {
              this.setState({
                widget: {
                  state: WidgetStateEnum.ConsentAgreement,
                },
              });
            }}
          />
        );
      case WidgetStateEnum.ConsentAgreement:
        return (
          <ConsentAgreement
            widgetStore={this.props.widgetStore}
            borrowerApi={this.props.borrowerApi}
            borrowerStore={this.props.borrowerStore}
            documentsApi={this.props.documentsApi}
            onFinished={this.handleOnFinished}
            onError={this.handleOnError}
            onDisagree={() => {
              this.setState({
                widget: {
                  state: WidgetStateEnum.Unconsented,
                },
              });
              this.loadingStore.show();
            }}
            onAgree={agreed => {
              if (agreed) {
                this.loadingStore.show();
              } else this.initialize();
            }}
          />
        );
      case WidgetStateEnum.Unauthorized:
        return (
          <UnauthorizedWidget
            onFinished={this.handleOnFinished}
            onError={this.handleOnError}
            widgetStore={this.props.widgetStore}
            errorCode={this.state.errorStatus}
          />
        );
      default:
        return 'Error';
    }
  }

  render() {
    return (
      <div className={styles.widget}>
        <WidgetHeader
          widgetStore={this.props.widgetStore}
          onViewLoanHistory={this.handleViewLoanHistory}
          widgetState={this.state.widget.state}
        />
        {this.loadingStore.showing && this.progress}
        <Frame className={styles.content} scrollable={true}>
          {this.widget}
        </Frame>
      </div>
    );
  }
}

export default Widget;

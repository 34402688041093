import * as React from 'react';
import styles from './loanSelectorMini.module.scss';
import Amount from '../../../../_common/displays/amount';
import NewAmountSliderMini from '../newAmountSliderMini';

export interface LoanSelectorMiniProps {
  min: number;
  max: number;
  value: number;
  decrementDisabled: boolean;
  incrementDisabled: boolean;
  onSliderChange(value: number): void;
  onDecrementClick(): void;
  onIncrementClick(): void;
  onSubmit(): void;
}

const NewLoanSelectorMini: React.FC<LoanSelectorMiniProps> = ({
  min,
  max,
  value,
  decrementDisabled,
  incrementDisabled,
  onSliderChange,
  onDecrementClick,
  onIncrementClick,
  onSubmit,
}) => {
  return (
    <div className={styles.selector}>
      <div className={styles.amountWrapper}>
        <div className={styles.text}>
          <span>Available Credit</span>
        </div>
        <Amount
          value={value}
          valueClassName={styles.amount}
          currencyClassName={styles.currency}
        />
      </div>

      <div className={styles.slider}>
        <div className={styles.slideWrapper}>
          <NewAmountSliderMini
            min={min}
            max={max}
            value={value}
            step={1000}
            onChange={onSliderChange}
            onDecrementClick={onDecrementClick}
            onIncrementClick={onIncrementClick}
            decrementDisabled={decrementDisabled}
            incrementDisabled={incrementDisabled}
          />
        </div>
        <div className={styles.btnWrapper}>
          <button className={`${styles.button} primaryBtn`} onClick={onSubmit}>
            <span>Get Cash</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewLoanSelectorMini;

import * as React from 'react';
import styles from './circleProgress.module.css';

export interface CircleProgress {
  width?: number;
  height?: number;
}
const CircleProgress: React.SFC<CircleProgress> = props => {
  const width = props.width || 100;
  const height = props.height || 100;
  return (
    <span className={styles.outer} style={{ width, height }}>
      <span className={styles.inner} />
    </span>
  );
};

export default CircleProgress;

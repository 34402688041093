import * as React from 'react';
import styles from './companyNotEligibleWidget.module.scss';
import { ChildWidgetProps } from '../..';
import { event } from '../../../../../utils/tracking';
import { WidgetStateEnum } from '../../../../../models/dtos/enums';
import { convertWidgetStateToGACategory } from '../../../../../models/dtos/widget';

export interface CompanyNotEligibleWidgetProps extends ChildWidgetProps {
  url: string;
  text: string;
  buttonText: string;
  base64Header: string;
  base64Content: string;
  widgetState: WidgetStateEnum;
}

class CompanyNotEligibleWidget extends React.Component<CompanyNotEligibleWidgetProps> {
  constructor(props: CompanyNotEligibleWidgetProps) {
    super(props);

    this.props.widgetStore.setMenuVisibility(false);
    this.props.widgetStore.setCustomHeader(this.props.base64Header);
  }
  componentDidMount() {
    this.props.onFinished();
  }

  componentWillUnmount() {
    this.props.widgetStore.setCustomHeader('');
  }

  handleOnClick = () => {
    event({
      category: convertWidgetStateToGACategory(this.props.widgetState),
      action: 'Click Find Out More button',
    });
    if (this.props.url) window.open(this.props.url);
  };

  render() {
    return (
      <div className={styles.widget}>
        <div className={styles.imageContainer}>
          <img
            src={`data:image/png;base64, ${this.props.base64Content}`}
            className={styles.image}
            alt="content"
          />
        </div>
        <div className={styles.footer}>
          <span className={styles.text}>{this.props.text}</span>
          <div className={styles.btnWrapper}>
            <button className={styles.button} onClick={this.handleOnClick}>
              <span>Explore</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CompanyNotEligibleWidget;

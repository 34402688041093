import * as React from 'react';
import clsx from 'clsx';
import styles from './frame.module.scss';

export interface FrameProps {
  className?: string;
  scrollable?: boolean;
}

const Frame: React.FC<FrameProps> = ({ className, scrollable = false, children }) => {
  return (
    <div
      className={clsx(
        styles.frame,
        { [styles.scrollbar]: scrollable, [styles.grayScrollbar]: scrollable },
        className
      )}
    >
      {children}
    </div>
  );
};

export default Frame;

import * as React from 'react';
import { inject } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import { AuthApi } from '../../apis/authApi';
import { AdminApi } from './../../apis/adminApi';
import { AppStore } from '../../stores/appStore';
import { dependencies } from '../../models/constants';

export interface HomeProps
  extends RouteComponentProps<{ token: string; display: string }> {
  authApi: AuthApi;
  adminApi: AdminApi;
  appStore: AppStore;
}

@inject(...dependencies)
class Home extends React.Component<HomeProps> {
  componentDidMount() {
    localStorage.setItem('token', this.props.match!.params.token);
    this.navigate();
  }

  async navigate() {
    try {
      const domain = await this.props.adminApi.getClientDomain();
      this.props.appStore.setDomain(domain);
      const { display } = this.props.match!.params;
      if (!display || display.toLocaleLowerCase() === 'page') {
        this.props.history.push('/loan-amount-selection');
      } else {
        this.props.history.push('/outstanding-loan-details-widget');
      }
    } catch {
      this.props.history.push('/unauthorized');
      localStorage.clear();
    }
  }

  render() {
    return <div />;
  }
}

export default Home;

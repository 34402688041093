import { AxiosApiBase } from './axiosApiBase';
import { WidgetDto } from '../models/dtos/widget';

export class WidgetApi {
  private axios = new AxiosApiBase();
  private baseUrl = '/api/v1/widget';

  getWidget(): Promise<WidgetDto> {
    return this.axios.get(this.baseUrl);
  }
}

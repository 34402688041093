import * as React from 'react';
import styles from './salaryDeductions.module.css';
import DateTimeDisplay, { DateTimeDisplayFormat } from '../_common/displays/dateTime';
import NumericDisplay from '../_common/displays/numeric/numericDisplay';
import SimpleLineProgress from '../_common/progress/simpleLine/simpleLineProgress';

export interface SalaryDeduction {
  payPeriod: number;
  paymentDue: string;
  amountDue: number;
  status?: string;
}

export interface SalaryDeductionsProps {
  deductions: SalaryDeduction[];
  styles?: { [key: string]: string };
  loading?: boolean;
  showStatus?: boolean;
}

class SalaryDeductions extends React.Component<SalaryDeductionsProps> {
  loadValueWithLoader(value: number | string | JSX.Element) {
    return (
      <>
        {this.props.loading ? (
          <div className={styles.progress}>
            <SimpleLineProgress />
          </div>
        ) : (
          value
        )}
      </>
    );
  }

  render() {
    const { deductions, showStatus } = this.props;
    const { trBackground1, trBackground2, container, table, header, headerItem } = this.props.styles
      ? this.props.styles
      : styles;
    return (
      <div className={container}>
        <table className={table}>
          <thead className={header}>
            <tr className={headerItem}>
              <th>Pay Period</th>
              <th>Due Date</th>
              <th>Amount Due</th>
              {!!showStatus && <th>Status</th>}
            </tr>
          </thead>
          <tbody>
            {deductions.map(d => {
              return (
                <tr
                  className={deductions.indexOf(d) % 2 !== 0 ? trBackground2 : trBackground1}
                  key={deductions.indexOf(d)}
                >
                  <td>{this.loadValueWithLoader(d.payPeriod)}</td>
                  <td>
                    {this.loadValueWithLoader(
                      <DateTimeDisplay
                        value={d.paymentDue}
                        format={DateTimeDisplayFormat.Month_d_yyyy}
                      />
                    )}
                  </td>
                  <td>
                    {this.loadValueWithLoader(
                      <>
                        P<NumericDisplay showComma={true} decimalCount={2} value={d.amountDue} />
                      </>
                    )}
                  </td>
                  {!!showStatus && <td>{this.loadValueWithLoader(d.status)}</td>}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default SalaryDeductions;

import { FeatureFlagDto,CompanyFeatureFlagDto2 } from '../models/dtos/featureFlag';
import { AxiosApiBase } from './axiosApiBase';

export class FeatureFlagApi {

  private axios = new AxiosApiBase();
  private baseUrl = '/api/v1/featureflags';

  async getFeatureFlag(key: string) {
    return await this.axios.get<FeatureFlagDto>(`${this.baseUrl}/${key}`);
  }
  static getFeatureFlagLogo(key: string) {
    const axios = new AxiosApiBase();
    const baseUrl = '/api/v1/featureflags';
    return axios.get<CompanyFeatureFlagDto2>(`${baseUrl}/${key}`);
  }
}

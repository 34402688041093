import { observable, action, computed } from 'mobx';
import { IBorrowerDto } from '../models/dtos/borrower';

export class BorrowerStore {
  @observable
  borrower: IBorrowerDto | undefined = undefined;

  @action
  setBorrower(borrower: IBorrowerDto) {
    this.borrower = borrower;
  }
  @computed
  get hasBorrower() {
    return !!this.borrower;
  }
}

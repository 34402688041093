import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { isJSON } from '../utils/helpers';
import { PaginationParam } from '../models/dtos/pagedResult';


export class ApiBase {
  private get defaultHeaders() {
    const clientId = localStorage.getItem('header.clientid');
    const companyId = localStorage.getItem('header.companyid');
    const employeeId = localStorage.getItem('header.employeeid');
    const hrDomain = localStorage.getItem('header.hrdomain');
    const payrollDb = localStorage.getItem('header.payrollDb');
    const hrDatabase = localStorage.getItem('header.hrDatabase');
    return {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'x-clientid': clientId,
      'x-companyid': companyId,
      'x-hrdomain': hrDomain,
      'x-employeeid': employeeId,
      'x-payrolldatabase': payrollDb,
      'x-hrdatabasename': hrDatabase
    };

  }
  getDefaultPaginationParam(){
    let param : PaginationParam={
      rowsPerPage:'10',
      pageNumber:null,
      sortColumn:null,
      sortOrder:null,
    }; 
    return param;
  } 

  objectToQueryString(obj) {
    var str = [];  
    for (var p in obj) 
      if(obj[p] != null || obj[p] != undefined){
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        } 
      }
    return str.join("&");
  }

  // Need to add response type cause the API returns either Object or String
  get<T>(url: string): Observable<T> {
    return ajax({
      url,
      method: 'GET',
      responseType: 'text',
      headers: this.defaultHeaders,
    }).pipe(
      // Convert response if object
      map(res => (isJSON(res.response) ? (JSON.parse(res.response) as T) : res.response)),
      // Convert response if object
      catchError(err => {
        err.response = isJSON(err.response) ? JSON.parse(err.response) : err.response;
        return throwError(err);
      })
    );
  }

  post<T>(url: string, resource?: any): Observable<T> {
    return ajax({
      url,
      method: 'POST',
      responseType: 'text',
      headers: this.defaultHeaders,
      body: resource,
    }).pipe(
      // Convert response if object
      map(res => (isJSON(res.response) ? (JSON.parse(res.response) as T) : res.response)),
      // Convert response if object
      catchError(err => {
        err.response = isJSON(err.response) ? JSON.parse(err.response) : err.response;
        return throwError(err);
      })
    );
  }

  put<T>(url: string, resource?: any): Observable<T> {
    return ajax
      .put(url, resource, this.defaultHeaders)
      .pipe(map(res => res.response as T));
  }

  delete(url: string): Observable<{}> {
    return ajax.delete(url, this.defaultHeaders);
  }
}

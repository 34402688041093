import { observable, action } from 'mobx';

export class DialogStore {
  @observable
  showing = false;

  @action
  hide() {
    this.showing = false;
  }

  @action
  show() {
    this.showing = true;
  }
}

import * as React from 'react';
import styles from './amountSliderMini.module.scss';
import NewRangeSlider from '../../../../_common/newRangeSlider';
import NewPlusMinusButton from '../../../../_common/buttons/newPlusMinus/plusMinusButton';

export interface AmountSliderMiniProps {
  min: number;
  max: number;
  value: number;
  step: number;
  decrementDisabled: boolean;
  incrementDisabled: boolean;
  onChange(value: number): void;
  onDecrementClick(): void;
  onIncrementClick(): void;
}

const NewAmountSliderMini: React.FC<AmountSliderMiniProps> = ({
  min,
  max,
  value,
  step,
  decrementDisabled,
  incrementDisabled,
  onChange,
  onDecrementClick,
  onIncrementClick,
}) => {
  return (
    <div className={styles.wrapper}>
      <NewPlusMinusButton
        isPlusButton={false}
        onClick={onDecrementClick}
        outlined={false}
        disabled={decrementDisabled}
      />
      <div className={styles.slider}>
        <NewRangeSlider
          min={min}
          max={max}
          value={value}
          step={step}
          onChange={onChange}
          handleStyle={{
            backgroundColor: 'var(--green-200)',
            width: 24,
            height: 24,
            marginTop: -12,
            border: '4px solid rgba(51, 218, 107, 0.3)',
            padding: 10,
            backgroundClip: 'padding-box',
            boxShadow: 'none',
          }}
        />
      </div>
      <NewPlusMinusButton
        isPlusButton={true}
        onClick={onIncrementClick}
        outlined={false}
        disabled={incrementDisabled}
      />
    </div>
  );
};

export default NewAmountSliderMini;

import * as React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

const ProtectedRoute: React.FunctionComponent<RouteProps> = ({
  component: Component,
  ...rest
}: RouteProps) => {
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('token') ? <Component {...props} /> : <Redirect to="/unauthorize" />
      }
    />
  );
};

export default ProtectedRoute;

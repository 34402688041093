import React, { CSSProperties, FC } from 'react';
import styles from './rangeSlider.module.scss';
import Slider from 'rc-slider';

export interface RangeSliderProps {
  min: number;
  max: number;
  step: number;
  value: number;
  defaultValue?: number;
  onChange?: (value: number) => void;
  onAfterChange?: (value: number) => void;
  handleStyle?: CSSProperties;
}

const NewRangeSlider: FC<RangeSliderProps> = ({
  min,
  max,
  step,
  value,
  defaultValue,
  onChange,
  onAfterChange,
  handleStyle,
}) => {
  return (
    <Slider
      min={min}
      max={max}
      onChange={onChange}
      onAfterChange={onAfterChange}
      value={value}
      step={step}
      defaultValue={defaultValue}
      className={styles.slider}
      trackStyle={{ marginTop: -1, height: 8 }}
      railStyle={{ marginTop: -1, height: 8 }}
      handleStyle={[
        {
          backgroundColor: 'var(--green-300)',
          width: 25,
          height: 25,
          border: '1px solid var(--green-300)',
          marginTop: -9,
          ...handleStyle,
        },
      ]}
    />
  );
};

export default NewRangeSlider;
